let API_URL_CONFIG = '/api';
let AUTH_SECRET = 'FGj89P6O93869feYchKLBC3hRIo386fFeyTxV48b';
let AUTH_SERVICE = 'mediation-local';
let ADMIN_CELLADDITION = '';
let PRINT_URL = '';

let env = window.location.hostname;
if (env.includes('localhost')) {
	AUTH_SERVICE = 'mediation-local';
	AUTH_SECRET = 'FGj89P6O93869feYchKLBC3hRIo386fFeyTxV48b';
	ADMIN_CELLADDITION = 'http://localhost:3003/admin';
	PRINT_URL = 'http://localhost:3010/';
} else if (env.includes('laddit.io')) {
	AUTH_SERVICE = 'mediation';
	AUTH_SECRET = 'JeHDjdfh673bdjbrleYchKLBC3hRIo386fFeyTxV48b';
	ADMIN_CELLADDITION = ' https://commande-en-ligne.laddit.io/admin/#/general';
	PRINT_URL = 'https://ticket-print.laddit.io/';
} else {
	AUTH_SECRET = 'PU6dAwjgkiPP6O938V48b69feYchKLBC3hRIo386fFeyTx';
	AUTH_SERVICE = 'mediation';
	ADMIN_CELLADDITION = 'https://commande-en-ligne.laddition.com/admin';
	PRINT_URL = 'https://ticket-qr.laddition.com/';
}

export default {
	API_URL_CONFIG,
	AUTH_SECRET,
	AUTH_SERVICE,
	ADMIN_CELLADDITION,
	PRINT_URL,
};
