import { push } from 'connected-react-router';
import { take, put } from 'redux-saga/effects';
import { BREADCRUMB_PUSH } from './types';

function* redirectAriane() {
	while (true) {
		const action = yield take(BREADCRUMB_PUSH);
		const { url } = action;
		yield put(push(url));
	}
}

export default redirectAriane;
