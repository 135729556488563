import {
	PRE_CHANGE_ACTIVE_PARTNER,
	CHANGE_ACTIVE_PARTNER,
	PRE_POST_OPTION,
	POST_OPTION,
	PRE_DELETE_OPTION,
	DELETE_OPTION,
	PRE_POST_MENU_TO_PARTNER,
	POST_MENU_TO_PARTNER,
} from './types';
import {
	take,
	put,
	call,
	all,
	select,
	actionChannel,
} from 'redux-saga/effects';
import { buffers } from 'redux-saga';
import { requestHandler } from '../../../../service/fetcher.saga';
import { I18n } from 'react-redux-i18n';
import { getVariableServer } from '../../../../utils';
import { showConfirm } from '../../../../Components/ConfirmRedux/service/saga';

const ENTITY = 'Partners';
const PRE_REQUEST_CHANGE_ACTIVE_PARTNER = 'PRE_REQUEST_CHANGE_ACTIVE_PARTNER';
const REQUEST_CHANGE_ACTIVE_PARTNER_FINISHED =
	'REQUEST_CHANGE_ACTIVE_PARTNER_FINISHED';
const PRE_REQUEST_POST_OPTION = 'PRE_REQUEST_POST_OPTION';
const REQUEST_POST_OPTION_FINISHED = 'REQUEST_POST_OPTION_FINISHED';

const PRE_REQUEST_DELETE_OPTION = 'PRE_REQUEST_DELETE_OPTION';
const REQUEST_DELETE_OPTION_FINISHED = 'REQUEST_DELETE_OPTION_FINISHED';

const PRE_REQUEST_POST_MENU_TO_PARTNER = 'PRE_REQUEST_POST_MENU_TO_PARTNER';
const REQUEST_POST_MENU_TO_PARTNER_FINISHED =
	'REQUEST_POST_MENU_TO_PARTNER_FINISHED';

function* preChangeActivePartner() {
	while (true) {
		const action = yield take(PRE_CHANGE_ACTIVE_PARTNER);
		if (action.body) {
			yield put({
				type: PRE_REQUEST_CHANGE_ACTIVE_PARTNER,
				body: action.body,
			});
			const { result } = yield take(REQUEST_CHANGE_ACTIVE_PARTNER_FINISHED);
			yield put({
				type: CHANGE_ACTIVE_PARTNER,
				data: result,
			});
		}
	}
}

function* watchChangeActivePartner() {
	while (true) {
		const { body } = yield take(PRE_REQUEST_CHANGE_ACTIVE_PARTNER);

		const refresh_token = window.localStorage.refresh_token;

		const { result } = yield call(
			requestHandler,
			ENTITY,
			'POST',
			{ ...body, refresh_token },
			'/active'
		);
		yield put({ type: REQUEST_CHANGE_ACTIVE_PARTNER_FINISHED, result });
	}
}

function* prePostOption() {
	const requestChannel = yield actionChannel(
		PRE_POST_OPTION,
		buffers.expanding()
	);
	while (true) {
		const action = yield take(requestChannel);
		if (action.body) {
			yield put({
				type: PRE_REQUEST_POST_OPTION,
				body: action.body,
			});
			const { result } = yield take(REQUEST_POST_OPTION_FINISHED);
			yield put({
				type: POST_OPTION,
				data: result,
			});
		}
	}
}

function* watchPostOption() {
	while (true) {
		const { body } = yield take(PRE_REQUEST_POST_OPTION);
		const { result } = yield call(
			requestHandler,
			ENTITY,
			'POST',
			body,
			'/options'
		);
		yield put({ type: REQUEST_POST_OPTION_FINISHED, result });
	}
}

function* preDeleteOption() {
	const requestChannel = yield actionChannel(
		PRE_DELETE_OPTION,
		buffers.expanding()
	);
	while (true) {
		const action = yield take(requestChannel);
		if (action.body) {
			yield put({
				type: PRE_REQUEST_DELETE_OPTION,
				body: action.body,
			});
			const { result } = yield take(REQUEST_DELETE_OPTION_FINISHED);
			yield put({
				type: DELETE_OPTION,
				data: action.body,
			});
		}
	}
}

function* watchDeleteOption() {
	while (true) {
		const { body } = yield take(PRE_REQUEST_DELETE_OPTION);
		const { result } = yield call(
			requestHandler,
			ENTITY,
			'DELETE',
			body,
			'/options'
		);
		yield put({ type: REQUEST_DELETE_OPTION_FINISHED, result });
	}
}

function* prePostMenuToPartner() {
	while (true) {
		const action = yield take(PRE_POST_MENU_TO_PARTNER);
		if (action.body) {
			yield put({
				type: PRE_REQUEST_POST_MENU_TO_PARTNER,
				body: action.body,
			});
			const { result } = yield take(REQUEST_POST_MENU_TO_PARTNER_FINISHED);
			yield put({
				type: POST_MENU_TO_PARTNER,
				data: result,
			});
		}
	}
}

function* watchPostMenuToPartner() {
	while (true) {
		const { body } = yield take(PRE_REQUEST_POST_MENU_TO_PARTNER);
		const { result } = yield call(
			requestHandler,
			ENTITY,
			'POST',
			body,
			'/menu'
		);
		yield put({ type: REQUEST_POST_MENU_TO_PARTNER_FINISHED, result });
	}
}

function* patchPartnerExternalId() {
	while (true) {
		const { body } = yield take('PRE_PATCH_PARTNER_EXTERNAL_ID');
		yield call(requestHandler, ENTITY, 'PATCH', body, 'externalId');
		yield put({
			type: 'PATCH_PARTNER',
			data: {
				prefix: body.prefix,
				key: 'external_id',
				value: body.external_id,
			},
		});
	}
}

function* preDisplayInfoPartner() {
	while (true) {
		let { prefix } = yield take('PARTNER_DISPLAY_INFO_PARTNER');
		yield call(displayInfoPartner, prefix);
	}
}

export function* displayInfoPartner(prefix) {
	const text = yield call(getDisplayPartnerInfo, prefix);

	yield call(showConfirm, text.join(' - '), 'info');
}

export function* getDisplayPartnerInfo(prefix) {
	const { partners } = yield select();

	let external_id =
		partners && partners[prefix] && partners[prefix].external_id
			? partners[prefix].external_id
			: null;
	let token =
		partners && partners[prefix] && partners[prefix].token_customer
			? partners[prefix].token_customer
			: null;

	let displayedInfo = {};

	const { variableServer } = yield select();
	switch (prefix) {
		case 'celladdition':
		case 'como':
		case 'ladditionTicket':
		case 'tessi':
		case 'ubereats':
		case 'ladditionMenu':
		case 'misterBooking':
		case 'pongo':
		case 'sunday':
		case 'obypay':
		case 'dishop':
		case 'melba':
		case 'rushour':
		case '1055': // dionysols
			displayedInfo = {
				external_id,
			};
			break;
		case 'deliverect':
			displayedInfo = {
				accountId: getVariableServer(variableServer, 'deliverectAccountId'),
				locationId: getVariableServer(variableServer, 'deliverectLocationId'),
			};
			break;
		case 'barratio':
			displayedInfo = {
				external_id: getVariableServer(variableServer, 'baratioToken'),
			};
			break;

		case 'dood':
			displayedInfo = {
				external_id: getVariableServer(variableServer, 'doodCustomerId'),
			};
			break;
		case 'koust':
			displayedInfo = {
				koust_token: getVariableServer(variableServer, 'koustUserToken'),
			};
			break;
		case 'tabesto':
			displayedInfo = {
				external_id: getVariableServer(variableServer, 'tabestoToken'),
			};
			break;
		case 'eviivo':
			displayedInfo = {
				propertyShortName: getVariableServer(
					variableServer,
					'propertyShortName'
				),
			};
			break;
		case 'mewsSystems':
			displayedInfo = {
				AccessTokenMews: getVariableServer(variableServer, 'accessTokenMews'),
			};
			break;
		case 'tickncook':
			displayedInfo = {
				account_id: getVariableServer(variableServer, 'tickncookAccountId'),
				kitchen_id: external_id,
			};
			break;
		default:
			displayedInfo = {
				external_id,
				token,
			};
	}

	return Object.keys(displayedInfo).map(key => {
		return (
			I18n.t('partner.popupInfo.' + key) +
			' : ' +
			(displayedInfo[key] || I18n.t('partner.infoUndefined'))
		);
	});
}

export default function* indexSaga() {
	yield all([
		preChangeActivePartner(),
		watchChangeActivePartner(),
		prePostOption(),
		watchPostOption(),
		preDeleteOption(),
		watchDeleteOption(),
		prePostMenuToPartner(),
		watchPostMenuToPartner(),
		patchPartnerExternalId(),
		preDisplayInfoPartner(),
	]);
}
