import i18nFlowSaga from './lang/saga';
import infoSaga from './Containers/Info/service/saga';
import menuSaga from './Containers/Menu/service/saga';
import authAutoFlowSaga from './service/auth/saga';
import paymentTypeSaga from './Containers/Partners/Components/Binding/service/saga';
import eventWebhookSaga from './Containers/EventWebhook/service/saga';
import exportSaga from './Containers/Partners/service/saga';
import arianeSaga from './Components/Breadcrumb/service/saga';
import routerSaga from './route/saga';
import variableSaga from './Containers/VariableServer/service/saga';
import confirmSaga from './Components/ConfirmRedux/service/saga';
import redirectSaga from './service/redirect/saga';
import errorSaga from './Components/ErrorBoundary/services/saga';
import { all } from 'redux-saga/effects';

export default function* indexSaga() {
	yield all([
		i18nFlowSaga(),
		routerSaga(),
		authAutoFlowSaga(),
		infoSaga(),
		menuSaga(),
		paymentTypeSaga(),
		eventWebhookSaga(),
		exportSaga(),
		arianeSaga(),
		variableSaga(),
		confirmSaga(),
		redirectSaga(),
		errorSaga(),
	]);
}
