import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import './style.scss';
import PropTypes from 'prop-types';

class Btn extends Component {
	render() {
		return (
			<div className="red-button" style={this.props.style}>
				<Button
					variant="contained"
					disabled={this.props.disabled}
					{...this.props}>
					{this.props.children}
				</Button>
			</div>
		);
	}
}

/*** 
 * Exemple d'implémentation
 * <Button
    variant="contained"
    className="submitButton"
    type="submit"
    disabled={false}
  >
    Texte
  </Button>
 * ***/

Btn.propTypes = {
	style: PropTypes.object,
	disabled: PropTypes.bool,
	children: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.array,
		PropTypes.object,
	]),
};

export default Btn;
