import React, { Component } from 'react';
import PartnerContact from '../../../../Components/PartnerContact';
import './style.scss';

class PartnerContainer extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		if (!this.props.id) {
			window.location = '/';
			return '';
		}
		return (
			<div className="content-container">
				<h1>{this.props.name}</h1>
				<PartnerContact prefix={this.props.prefix} />
				{this.props.children}
			</div>
		);
	}
}

export default PartnerContainer;
