import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import modulePartners from '../Containers/Partners';
import dashboard from '../Containers/Dashboard';
import moduleInfo from '../Containers/Info';
import moduleEventWebhook from '../Containers/EventWebhook';
import moduleMenu from '../Containers/Menu';
import { getPartners } from '../Containers/Partners/utils';
import { ConnectedRouter } from 'connected-react-router';
import { history } from '../store';
import quatreCentQuatre from '../Components/404';
import GlobalPartner from '../Containers/Partners/Containers/index';

export default class route extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const partners = getPartners();
		return (
			<ConnectedRouter history={history}>
				<Switch>
					<Route exact path={'/'} component={dashboard} />
					<Route exact path={'/partners'} component={modulePartners} />
					<Route exact path={'/info'} component={moduleInfo} />
					<Route exact path={'/menu'} component={moduleMenu} />
					<Route exact path={'/eventWebhook'} component={moduleEventWebhook} />
					<Route path={'/redirect'} component={''} />
					<Route path={'/partners/:prefix'} component={GlobalPartner} />

					<Route component={quatreCentQuatre} />
				</Switch>
			</ConnectedRouter>
		);
	}
}
