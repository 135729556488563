import React, { Component } from 'react';
import './style.scss';
import { connect } from 'react-redux';
import PaymentAsso from '../../Components/Binding';
import { I18n } from 'react-redux-i18n';
import Options from '../../Components/Options';
import { getPartner, getExportActive } from '../../service/partners/selector';
import PartnerContainer from '../PartnerContainer';
import Export from '../../Components/Export';
import SupplementManager from '../../Components/SupplementExtra';
import Config from '../../Components/Config';
import ProductThreshold from '../../Components/ProductQuantity';
import { getData } from '../../service/partnersData/actions';
import PosStatus from './Components/PosStatus';
import OrderValidation from './Components/OrderValidation';

class UberEats extends Component {
	componentDidMount() {
		if (!this.props.partnerData) {
			this.props.getPartnerData('ubereats');
		}
	}
	render() {
		const { partner, partnerData } = this.props;
		return (
			<PartnerContainer
				name={I18n.t(`partner.prefix.${partner.prefix}`)}
				id={partner ? partner.id : null}
				prefix={partner ? partner.prefix : null}>
				<PosStatus status={partnerData && partnerData.integration_enabled} />
				<Export {...this.props} />
				<div className="gestion-button">
					<SupplementManager prefix={partner.prefix} />
					<PaymentAsso prefix={partner.prefix} />
				</div>
				<div className="gestion-button">
					<ProductThreshold prefix={partner.prefix} />
				</div>
				<OrderValidation />
				<Options prefix_partner={partner.prefix || ''} />
				<Config prefix_partner={partner.prefix || ''} />
			</PartnerContainer>
		);
	}
}

const mapStateToProps = (state, props) => {
	const partner = getPartner(state, props);
	const data = state.partnersData['ubereats'];
	return {
		partner,
		list: state.exports.list,
		partnerData: data,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getPartnerData: prefix => dispatch(getData(prefix)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UberEats);
