import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import User from './Components/User';
import CustomBubbles from './Components/CustomBubbles';
import BackgroundCircle from './Components/backgroundCircle';
import Routes from './route';
import Loader from './Components/Loader';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SnackbarProvider } from 'notistack';
import Notifier from './Components/Notifier';
import Breadcrumb from './Components/Breadcrumb';
import { connect } from 'react-redux';
import VariableModal from './Components/VariableModal';
import AllReadyInUse from './Components/AllReadyInUse';
import { sendUnloadBeacon } from './service/fetcher.saga';
import ErrorBoundary from './Components/ErrorBoundary';
import ConfirmRedux from './Components/ConfirmRedux';
import { version } from '../package.json';
import { HeaderLaddition } from '@capps/laddition-storybook';
import { signout } from './service/auth/saga';

class App extends Component {
	componentDidMount() {
		window.addEventListener('beforeunload', this.beforeUnload, false);
	}

	beforeUnload() {
		sendUnloadBeacon();
	}
	render() {
		const searchParams = new URLSearchParams(document.location.search);
		const asIframe = searchParams.has("iframe") && searchParams.get("iframe") === "1";

		return (
			<BrowserRouter>
				<MuiThemeProvider theme={theme}>
					<SnackbarProvider maxSnack={3}>
						<div className="App-mask">
							<div className="App">
								<Notifier />
								<BackgroundCircle />
								<CustomBubbles />
								{this.props.receivedAll ? (
									<React.Fragment>
										{
											// Si la page n'est pas appelé depuis une iframe alors on peut afficher le header et breadcrumb
											!asIframe ?
												<>
														<HeaderLaddition
															buttonProps={{
																onClick: () => signout(),
															}}
														/>
														<Breadcrumb />
												</>
											:
												""
										}
										<ToastContainer />
										<AllReadyInUse />
										<VariableModal />
										<ErrorBoundary>
											<Routes />
										</ErrorBoundary>
										<ConfirmRedux />
									</React.Fragment>
								) : null}
								<Loader />
							</div>
						</div>
					</SnackbarProvider>
				</MuiThemeProvider>
			</BrowserRouter>
		);
	}
}

const mapStateToProps = (state, props) => {
	return {
		receivedAll: state.loading.receivedAll,
	};
};

export default connect(mapStateToProps)(App);
