import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { getPartnerPrefix } from '../../Containers/Partners/utils';
import { BREADCRUMB_PUSH } from './service/types';
import './style.scss';

const Breadcrumb = props => {
	let filAriane;
	let to = '';
	let urlByIndex = [];
	if (window && window.localStorage && window.localStorage.lastVisited) {
		const newUrl = window.localStorage.lastVisited;
		window.localStorage.removeItem('lastVisited');
		props.redirectUrl(newUrl);
	}
	if (props.router.pathname !== '/') {
		const arianeSplit = props.router.pathname.split('/');
		filAriane = arianeSplit.map((ariane, index) => {
			if (ariane === '') {
				urlByIndex.push('/');
				return (
					<div key={0} onClick={() => props.redirectUrl(urlByIndex[index])}>
						<p>{I18n.t('breadcrumb.home')}</p>
					</div>
				);
			} else {
				to += '/' + ariane;
				let prefix = getPartnerPrefix(to);
				urlByIndex.push(to);
				if (prefix === false) {
					return (
						<div
							key={index}
							onClick={() => props.redirectUrl(urlByIndex[index])}>
							<p>{I18n.t('breadcrumb.' + ariane)}</p>
						</div>
					);
				} else {
					return (
						<div
							key={index}
							onClick={() => props.redirectUrl(urlByIndex[index])}>
							<p>{I18n.t('partner.prefix.' + prefix)}</p>
						</div>
					);
				}
			}
		});
	} else {
		filAriane = (
			<div key={0}>
				<p>{I18n.t('breadcrumb.home')}</p>
			</div>
		);
	}

	return <div className="ariane-container">{filAriane}</div>;
};

const mapStateToProps = state => {
	return {
		router: state.router.location,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		redirectUrl: url => {
			dispatch({ type: BREADCRUMB_PUSH, url });
		},
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumb);
