import axios from 'axios';
import { call, all, put, fork, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { toast } from 'react-toastify';
import {
	RECEIVE_INFO,
	RECEIVE_PICTURES,
} from '../Containers/Info/service/types';
import { RECEIVE_PICTURES_ONLINE } from '../Containers/Menu/service/types';
import { RECEIVE_VARIABLE_SERVER } from '../Containers/VariableServer/service/types';
import {
	RECEIVE_FLOOR,
	RECEIVE_PRICE_CATEGORY,
	RECEIVE_PAYMENT_TYPE,
	RECEIVE_FLOOR_ALL,
} from '../Containers/Partners/Components/Binding/service/types';
import { RECEIVE_EVENTS } from '../Containers/EventWebhook/service/types';
import { RECEIVE_PARTNERS } from '../Containers/Partners/service/partners/types';
import { RECEIVE_PRODUCTS } from '../Containers/Partners/Components/ProductsSelector/service/products/types';
import { RECEIVE_MENU } from '../Containers/Partners/Components/ProductsSelector/service/menus/types';
import { RECEIVE_CATEGORIES } from '../Containers/Partners/Components/ProductsSelector/service/categories/types';
import { RECEIVE_VARIABLE } from '../Containers/Info/service/types';
import {
	RECEIVE_PARTNERS_CONFIG,
	RECEIVE_PARTNERS_CONFIG_CLIENT,
	RECEIVE_PARTNERS_CONFIG_STEP,
} from '../Containers/Partners/service/partnersConfig/types';
import {
	SHOW_LOADER,
	HIDE_LOADER,
	INIT_RECEIVED_ALL,
	RECEIVED_ALL,
} from '../Components/Loader/service/types';
import {
	normalize,
	normalizeByIdMenu,
	exportCategorySupplementByIdMenu,
} from './utils';
import {
	RECEIVE_PRODUCT_EXTRA,
	RECEIVE_EXPORT,
	RECEIVE_PARTNER_KEYS,
	RECEIVE_PARTNERS_STOCK_ACTIVE_LIVE,
	RECEIVE_STOCK_MANAGEMENT_DISABLED_PRODUCTS,
} from '../Containers/Partners/service/export/types';
import {
	RECEIVE_CATEGORY_SUPPLEMENT,
	RECEIVE_CATEGORY_SUPPLEMENT_EXTRA,
	RECEIVE_SUPPLEMENT_EXTRA,
} from '../Containers/Partners/Components/SupplementExtra/service/types';
import config from '../config';

import { I18n } from 'react-redux-i18n';
import { SET_MULTI_SESSION } from './auth/types';
import {
	UPDATE_STOCK_MANAGEMENT_STATUS,
	RECEIVE_UBEREATS_CLIENT_ID,
	RECEIVE_USER,
} from './user/types';
import { RECEIVE_EXTERNAL_DATA } from '../Containers/Partners/Components/ExternalDataAssignation/service/reducer';

let API_URL;

API_URL = config.API_URL_CONFIG;
export const getApiUrl = () => API_URL;

const EXPORT = 'export';
const BINDING = 'binding';
const PARTNERS_CONFIG = 'partnersConfig';
const STOCK_MANAGEMENT = 'stockManagement';
export const ENTITIES = {
	INFO: 'info',
	PAYMENT_TYPE: 'paymentType',
	EVENTS: 'Events',
	PARTNERS: 'partners',
	PARTNERS_CONFIG: 'partnersConfig',
	PARTNERS_CONFIG_CLIENT: `${PARTNERS_CONFIG}/config`,
	PARTNERS_CONFIG_STEP: `${PARTNERS_CONFIG}/configStepByStep`,
	EVENTS: 'events',
	BINDING: {
		ROOT: BINDING,
		FLOOR: `${BINDING}/floor`,
		FLOOR_ALL: `${BINDING}/floor/all`,
		PRICE_CATEGORY: `${BINDING}/priceCategory`,
		PAYMENT_TYPE: `${BINDING}/paymentType`,
	},
	EXPORT: {
		ROOT: EXPORT,
		PRODUCTS: `${EXPORT}/products`,
		CATEGORIES: `${EXPORT}/categories`,
		MENUS: `${EXPORT}/menus`,
		PRODUCT_EXTRA: `${EXPORT}/productExtra`,
		PARTNER_KEYS: `${EXPORT}/partnerKeys`,
		SECTION: `${EXPORT}/section`,
		SECTION_WEIGHT: `${EXPORT}/section/weight`,
		PRODUCT_WEIGHT: `${EXPORT}/products/weight`,
		ACTIVE: `${EXPORT}/active`,
		CATEGORY_SUPPLEMENT_EXTRA: `${EXPORT}/categorySupplementExtra`,
		SUPPLEMENT_EXTRA: `${EXPORT}/supplementExtra`,
		VARIABLE: `${EXPORT}/variable`,
		CATEGORY_SUPPLEMENTS: `${EXPORT}/categorySupplements`,
		NOTIF_IPAD_CHANGES: `${EXPORT}/notifIpadChanges`,
		COPY_EXPORT: `${EXPORT}/copy`,
	},
	VARIABLE: 'variable',
	UBEREATS_CLIENT_ID: `variable/ubereatsClientId`,
	PARTNERS_STOCK_ACTIVE_LIVE: `variable/partnersStockActiveLive`,
	PICTURES: 'pictures',
	PICTURES_ONLINE: 'pictures/online',
	STOCK_MANAGEMENT: {
		ROOT: STOCK_MANAGEMENT,
		STATUS: `${STOCK_MANAGEMENT}/status`,
		DISABLED_PRODUCTS: `${STOCK_MANAGEMENT}/disabledProducts`,
	},
	USER: 'user',
	EXTERNAL_DATA: 'externalData',
};

const ENTITY_TO_TYPE = {
	[ENTITIES.INFO]: RECEIVE_INFO,
	[ENTITIES.VARIABLE]: RECEIVE_VARIABLE_SERVER,
	[ENTITIES.UBEREATS_CLIENT_ID]: RECEIVE_UBEREATS_CLIENT_ID,
	[ENTITIES.PARTNERS]: RECEIVE_PARTNERS,
	[ENTITIES.PARTNERS_CONFIG]: RECEIVE_PARTNERS_CONFIG,
	[ENTITIES.PARTNERS_CONFIG_CLIENT]: RECEIVE_PARTNERS_CONFIG_CLIENT,
	[ENTITIES.PARTNERS_CONFIG_STEP]: RECEIVE_PARTNERS_CONFIG_STEP,
	[ENTITIES.EVENTS]: RECEIVE_EVENTS,
	[ENTITIES.EXPORT.PRODUCTS]: RECEIVE_PRODUCTS,
	[ENTITIES.EXPORT.CATEGORIES]: RECEIVE_CATEGORIES,
	[ENTITIES.EXPORT.MENUS]: RECEIVE_MENU,
	[ENTITIES.EXPORT.ROOT]: RECEIVE_EXPORT,
	[ENTITIES.EXPORT.PRODUCT_EXTRA]: RECEIVE_PRODUCT_EXTRA,
	[ENTITIES.EXPORT.PARTNER_KEYS]: RECEIVE_PARTNER_KEYS,
	[ENTITIES.EXPORT
		.CATEGORY_SUPPLEMENT_EXTRA]: RECEIVE_CATEGORY_SUPPLEMENT_EXTRA,
	[ENTITIES.EXPORT.SUPPLEMENT_EXTRA]: RECEIVE_SUPPLEMENT_EXTRA,
	[ENTITIES.BINDING.FLOOR]: RECEIVE_FLOOR,
	[ENTITIES.BINDING.FLOOR_ALL]: RECEIVE_FLOOR_ALL,
	[ENTITIES.BINDING.PAYMENT_TYPE]: RECEIVE_PAYMENT_TYPE,
	[ENTITIES.BINDING.PRICE_CATEGORY]: RECEIVE_PRICE_CATEGORY,
	[ENTITIES.EXPORT.VARIABLE]: RECEIVE_VARIABLE,
	[ENTITIES.EXPORT.CATEGORY_SUPPLEMENTS]: RECEIVE_CATEGORY_SUPPLEMENT,
	[ENTITIES.PICTURES]: RECEIVE_PICTURES,
	[ENTITIES.PICTURES_ONLINE]: RECEIVE_PICTURES_ONLINE,
	[ENTITIES.STOCK_MANAGEMENT.STATUS]: UPDATE_STOCK_MANAGEMENT_STATUS,
	[ENTITIES.STOCK_MANAGEMENT
		.DISABLED_PRODUCTS]: RECEIVE_STOCK_MANAGEMENT_DISABLED_PRODUCTS,
	[ENTITIES.USER]: RECEIVE_USER,
	[ENTITIES.EXTERNAL_DATA]: RECEIVE_EXTERNAL_DATA,
	[ENTITIES.PARTNERS_STOCK_ACTIVE_LIVE]: RECEIVE_PARTNERS_STOCK_ACTIVE_LIVE,
};

const retryRequests = [
	ENTITIES.BINDING.FLOOR,
	ENTITIES.BINDING.PAYMENT_TYPE,
	ENTITIES.BINDING.PRICE_CATEGORY,
	ENTITIES.EXPORT.CATEGORY_SUPPLEMENTS,
	ENTITIES.EXPORT.VARIABLE,
	ENTITIES.EXPORT.PRODUCTS,
	ENTITIES.EXPORT.CATEGORIES,
	ENTITIES.EXPORT.MENUS,
	ENTITIES.STOCK_MANAGEMENT.STATUS,
	ENTITIES.STOCK_MANAGEMENT.DISABLED_PRODUCTS,
];

const REQUEST_TIMEOUT = 30000;

/**
 * Fetch and url and return the result
 * @param {String} url - endpoint to reach
 * @param {[String]} method - method to use (get, post, ...)
 * @param {[Object]} params
 * @param {[Object]} additionalHeaders - header to add
 * @param {[Object]} options - options to add
 * @return response or err depend on the result obviously
 */
export function fetcher(
	url,
	method = 'GET',
	params = {},
	additionalHeaders = {},
	options = {}
) {
	const headers = {
		'Content-Type': 'application/json',
		...additionalHeaders,
	};

	let requestOptions = {
		method,
		headers,
		url,
		timeout: REQUEST_TIMEOUT,
		...options,
	};

	if (method === 'GET' && params && Object.keys(params).length) {
		requestOptions.params = params;
	} else {
		requestOptions.data = params;
	}

	return axios(requestOptions)
		.then(res => {
			return res.data;
		})
		.catch(error => {
			if (error.response) {
				// The request was made and the server responded with a status code that falls out of the range of 2xx
				console.log(error.response.data);
				console.log(error.response.status);
				console.log(error.response.headers);
			} else if (error.request) {
				// The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
				console.log(error.request);
			} else {
				// Something happened in setting up the request that triggered an Error
				console.log('Error', error.message);
			}
			console.log(error.config);
			return error.response.data;
		});
}

export const getApiHeaders = () => {
	return { 'access-token': window.localStorage.access_token };
};

/**
 * Fetch or call an entity with some params
 * @param {String} endPoint - entity to call
 * @param {String} method - POST, GET ....
 * @param [{Object}] body - url or body params
 * @param [{String}] route - additional route ( [apiUrl]/[endPoint]/[route])
 * @param [{Object}] options
 * @param [{Boolean}] options.secured - with access token or not
 */
export function* requestHandler(
	endPoint,
	method = 'GET',
	params = {},
	route = '',
	options = {},
	useLoader = true,
	textLoader = ''
) {
	try {
		const store = yield select();
		let receivedAll = store.loading.receivedAll;
		if (useLoader) {
			yield put({ type: SHOW_LOADER, text: textLoader });
		}

		let url = `${API_URL}/${endPoint}`;
		if (route && route !== '') {
			url += `/${route}`;
		}

		let header = getApiHeaders();
		header = { ...header, 'x-guid': sessionStorage.getItem('guid') };
		if (params.refresh_token) {
			header = { ...header, refreshToken: params.refresh_token };
		}
		const result = yield call(fetcher, url, method, params, header, options);
		if (receivedAll && useLoader) {
			yield put({ type: HIDE_LOADER });
		}
		if (options.responseType === 'blob' && endPoint === 'export') {
			return {
				entity: endPoint,
				result: result,
			};
		}
		if (result.success) {
			return {
				entity: endPoint,
				result: result.success ? result.result : false,
			};
		} else {
			if (result.multiSession) {
				yield put({ type: SET_MULTI_SESSION, multiSession: true });
				return {
					entity: endPoint,
					result: true,
				};
			} else if (result.error) {
				return { result: false, error: result.error };
			}

			return {
				entity: endPoint,
				result: false,
			};
		}
	} catch (err) {
		if (useLoader) yield put({ type: HIDE_LOADER });
		return err;
	}
}

export function* fetchAll() {
	yield put({ type: INIT_RECEIVED_ALL });
	yield put({ type: SHOW_LOADER });

	const refresh_token = window.localStorage.refresh_token;
	const resultAll = yield all([
		call(requestHandler, ENTITIES.INFO, 'GET', null, 'infoRestaurant'),
		call(requestHandler, ENTITIES.USER),
		call(requestHandler, ENTITIES.VARIABLE),
		call(requestHandler, ENTITIES.UBEREATS_CLIENT_ID),
		call(requestHandler, ENTITIES.PARTNERS, 'GET', { refresh_token }),
		call(requestHandler, ENTITIES.PARTNERS_CONFIG),
		call(requestHandler, ENTITIES.PARTNERS_CONFIG_CLIENT),
		call(requestHandler, ENTITIES.PARTNERS_CONFIG_STEP),
		call(requestHandler, ENTITIES.EVENTS),
		call(requestHandler, ENTITIES.EXPORT.ROOT),
		call(requestHandler, ENTITIES.EXPORT.PRODUCT_EXTRA),
		call(requestHandler, ENTITIES.EXPORT.PARTNER_KEYS),
		call(requestHandler, ENTITIES.EXPORT.CATEGORY_SUPPLEMENT_EXTRA),
		call(requestHandler, ENTITIES.EXPORT.SUPPLEMENT_EXTRA),
		call(requestHandler, ENTITIES.BINDING.FLOOR),
		call(requestHandler, ENTITIES.BINDING.FLOOR_ALL),
		call(requestHandler, ENTITIES.BINDING.PAYMENT_TYPE),
		call(requestHandler, ENTITIES.BINDING.PRICE_CATEGORY),
		call(requestHandler, ENTITIES.EXPORT.VARIABLE),
		call(requestHandler, ENTITIES.PICTURES_ONLINE),
		call(requestHandler, ENTITIES.PICTURES),
		call(requestHandler, ENTITIES.STOCK_MANAGEMENT.STATUS),
		call(requestHandler, ENTITIES.STOCK_MANAGEMENT.DISABLED_PRODUCTS),
		call(requestHandler, ENTITIES.EXTERNAL_DATA),
		call(requestHandler, ENTITIES.PARTNERS_STOCK_ACTIVE_LIVE),
	]);

	if (resultAll) {
		const failedCalls = yield call(entityToReducer, resultAll);

		yield call(handleRetry, failedCalls);
	}
	yield put({ type: RECEIVED_ALL });
	yield put({ type: HIDE_LOADER });

	yield fork(getMenuElements);
}

function* entityToReducer(resultAll) {
	let failed = [];
	for (let result of resultAll) {
		if (result.result) {
			if (result.entity == ENTITIES.PARTNERS) {
				if (
					!result.result.hasOwnProperty('mediation') ||
					!result.result.hasOwnProperty('addDataWeb') ||
					!result.result.hasOwnProperty('bosav') ||
					!result.result.hasOwnProperty('jeff')
				) {
					yield call(initLinkDataMed);
				}
			}
			yield put({
				type: ENTITY_TO_TYPE[result.entity],
				data: result.result,
			});
		} else if (result.entity) {
			failed.push(result.entity);
		}
	}
	return failed;
}

function* handleRetry(failedCalls = []) {
	if (failedCalls.length) {
		let failed = failedCalls.filter(e => retryRequests.includes(e));
		let failedNoRetry = failedCalls.filter(e => !retryRequests.includes(e));
		failed = yield call(retryCall, failed);
		const allFailed = [...failed, ...failedNoRetry];
		for (let endPoint of allFailed) {
			// toast.error(I18n.t("request.fail", { endPoint }), {
			//   className: "toast-style",
			// });
		}
	}
}

function* retryCall(entities) {
	let failed = [...entities];
	let nbRetry = 1;
	while (failed.length && nbRetry < 5) {
		nbRetry++;
		const requests = failed.map(entity =>
			call(requestHandler, entity, 'GET', {}, '', { timeout: 30000 }, false)
		);
		const resultAll = yield all(requests);
		failed = yield call(entityToReducer, resultAll);
	}
	return failed;
}

function* getMenuElements() {
	const resultAll = yield all([
		call(
			requestHandler,
			ENTITIES.EXPORT.MENUS,
			'GET',
			{},
			'',
			{ timeout: 20000 },
			false
		),
		call(
			requestHandler,
			ENTITIES.EXPORT.CATEGORIES,
			'GET',
			{},
			'',
			{ timeout: 20000 },
			false
		),
		call(
			requestHandler,
			ENTITIES.EXPORT.PRODUCTS,
			'GET',
			{},
			'',
			{ timeout: 120000 },
			false
		),
		call(
			requestHandler,
			ENTITIES.EXPORT.CATEGORY_SUPPLEMENTS,
			'GET',
			{},
			'',
			{ timeout: 20000 },
			false
		),
	]);

	const resultAllMapped = resultAll.map(result => {
		if (result.result) {
			if (result.entity == ENTITIES.EXPORT.PRODUCTS) {
				result.result = result.result.reduce((acc, res) => {
					if (res.type === 'menu') {
						let compteurOptionnal = 0;
						let compteurRequired = 0;
						res.menuLevel.forEach(menu => {
							if (!menu.required) {
								compteurOptionnal++;
							} else {
								compteurRequired++;
							}
						});
						if (compteurOptionnal <= 2 && compteurRequired >= 1) {
							acc.push(res);
						}
					} else {
						acc.push(res);
					}
					return acc;
				}, []);
			}
			result.result =
				result.entity === ENTITIES.EXPORT.MENUS
					? normalize(result.result)
					: normalizeByIdMenu(result.result);
		}
		return result;
	});

	const failedCalls = yield call(entityToReducer, resultAllMapped);

	yield call(handleRetry, failedCalls);
}

export function* retrieveVariable() {
	let variables = yield call(
		requestHandler,
		ENTITIES.VARIABLE,
		'GET',
		{},
		'',
		{},
		false
	);
	yield put({
		type: ENTITY_TO_TYPE[ENTITIES.VARIABLE],
		data: variables.result,
	});
}

export const sendUnloadBeacon = () => {
	if (!navigator.sendBeacon) return;
	let headers = {
		type: 'text/plain; charset=UTF-8',
	};

	let body = {
		guid: sessionStorage.getItem('guid'),
		...getApiHeaders(),
	};

	let blob = new Blob([JSON.stringify(body)], headers);

	window.navigator.sendBeacon(API_URL + '/variable/unload', blob);
};

export function* refreshBindings() {
	const resultAll = yield all([
		call(requestHandler, ENTITIES.BINDING.FLOOR),
		call(requestHandler, ENTITIES.BINDING.FLOOR_ALL),
		call(requestHandler, ENTITIES.BINDING.PAYMENT_TYPE),
		call(requestHandler, ENTITIES.BINDING.PRICE_CATEGORY),
	]);

	if (resultAll) {
		const failedCalls = yield call(entityToReducer, resultAll);

		yield call(handleRetry, failedCalls);
	}
}

function* initLinkDataMed() {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	let isReloaded = urlParams.get('reload');

	let error = false;
	if (!isReloaded) {
		const result = yield call(
			requestHandler,
			'init',
			'POST',
			{
				refresh_token: window.localStorage.getItem('refresh_token'),
			},
			'links',
			{ timeout: 20000 },
			true,
			I18n.t('firstLogin.init')
		);

		if (result) {
			let url = new URL(window.location.href);
			url.searchParams.append('reload', true);
			window.location.replace(url);
		} else {
			error = true;
		}
	}

	if (isReloaded || error) {
		yield put({
			type: 'THROW_ERROR',
			text: I18n.t('errorBoundary.configError'),
			error: 'MISSING_REQUIRED_LINKING',
		});
	}
}
