import React, { Component } from 'react';
import './style.scss';
import { connect } from 'react-redux';
import Bindings from '../../Components/Binding';
import { I18n } from 'react-redux-i18n';
import Options from '../../Components/Options';
import { getPartner } from '../../service/partners/selector';
import PartnerContainer from '../PartnerContainer';
import Export from '../../Components/Export';
import SupplementManager from '../../Components/SupplementExtra';
import { deepEqual } from '../../utils';
import { getData } from '../../service/partnersData/actions';
import BrandChanConfiguration from '../../Components/BrandChanConfiguration';

class Rushour extends Component {
	shouldComponentUpdate(nextProps) {
		if (
			deepEqual(this.props.list, nextProps.list) &&
			deepEqual(this.props.variableServer, nextProps.variableServer)
		) {
			return false;
		}

		return true;
	}

	componentDidMount() {
		if (!this.props.partnerData) {
			this.props.getPartnerData('rushour');
		}
	}

	render() {
		const { partner } = this.props;

		return (
			<PartnerContainer
				name={I18n.t(`partner.prefix.${partner.prefix}`)}
				id={partner ? partner.id : null}
				prefix={partner ? partner.prefix : null}>
				<Export {...this.props} />

				<div className="gestion-button">
					<SupplementManager prefix={partner.prefix} />
					<Bindings prefix={partner.prefix} />
				</div>

				<BrandChanConfiguration prefix={partner.prefix} />

				<Options prefix_partner={partner.prefix || ''} />
			</PartnerContainer>
		);
	}
}

const mapStateToProps = (state, props) => {
	const partner = getPartner(state, props);
	const data = state.partnersData['rushour'];
	return {
		partner,
		list: state.exports.list,
		partnerData: data,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getPartnerData: prefix => dispatch(getData(prefix)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Rushour);
