import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from './Button';
import Icon from '@material-ui/icons/Menu';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import { LOGOUT } from '../service/auth/types';
import { confirm } from '../utils';
import { I18n } from 'react-redux-i18n';

class User extends Component {
	logout = e => {
		e.preventDefault();
		confirm(I18n.t('general.logout_confirm')).then(() => {
			this.props.logout();
		});
	};
	render() {
		return this.props.user && this.props.user.id ? (
			<div>
				<Button style={{ margin: 0 }} onClick={this.logout}>
					{this.props.user.username}
					<LogoutIcon style={{ marginLeft: 5, marginRight: -5 }} />
				</Button>
			</div>
		) : null;
	}
}
const mapStateToProps = state => {
	return { user: state.service.user };
};

const mapDispatchToProps = dispatch => {
	return {
		logout: () => {
			dispatch({ type: LOGOUT });
		},
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(User);
