import { createConfirmation } from 'react-confirm';
import Confirm from './Components/Confirm';

const conf = createConfirmation(Confirm);
/**
 * Trigger a confirmation popup. this is a promise so you must use .then(()=> {...}) after it
 * @param {String} confirmation  - label
 * @param {Object} options
 */
export function confirm(confirmation, options = {}) {
	return conf({ confirmation, options });
}

/**
 * Compare 2 object and return a boolean (might be not performant due ton JSON.stringofy use)
 * @param {Object} obj1
 * @param {Object} obj2
 * @returns {Boolean}
 */
export function deepEqual(obj1, obj2) {
	return JSON.stringify(obj1) === JSON.stringify(obj2);
}

/**
 * Compare 2 object and return all changed/new values from the newData
 * @param {Object} oldData
 * @param {Object} newData
 * @returns {Object} - changes {property : value}
 */
export function compareObjects(oldData, newData) {
	if (!newData || !oldData) {
		return {};
	}
	const changements = {};
	for (let index in newData) {
		if (oldData[index] !== newData[index]) {
			changements[index] = newData[index];
		}
	}
	return changements;
}

export function deepClone(data) {
	let clone = {};
	if (Array.isArray(data)) {
		clone = [];
	}
	for (var i in data) {
		if (data[i] != null && typeof data[i] == 'object')
			clone[i] = deepClone(data[i]);
		else clone[i] = data[i];
	}
	return clone;
}

export function isEmpty(string = '') {
	if (typeof string === 'string') {
		return string.trim() == '';
	} else {
		return false;
	}
}

export function sortBy(arr = [], prop) {
	return arr.reduce((acc, row) => {
		if (!acc.hasOwnProperty(row[prop])) {
			acc[row[prop]] = [];
		}
		acc[row[prop]].push(row);
		return acc;
	}, {});
}

export function getVariableServer(variables, key) {
	const variable = Object.values(variables).find(n => n.key_ === key);

	return variable ? variable.value : false;
}

export const splitStrLang = language => {
	return language
		? language.split('-')[1]
			? language.split('-')[1].toLowerCase()
			: language.split('-')[0].toLowerCase()
		: '';
};

export const checkCountryCode = language => {
	const langNavigator = splitStrLang(language);
	let countryCode = 'fr';
	if (langNavigator.includes('es', 'ca')) {
		countryCode = 'es';
	}
	return countryCode;
};

export const priceEuroToCents = price => {
	if (price > 0) {
		return parseInt(parseFloat(parseFloat(price).toFixed(2) * 100).toFixed(2));
	} else if (price === '') {
		return '';
	} else {
		return 0;
	}
};

export const getPartnerDocumentation = prefix => {
	if (
		[
			'celladdition',
			'ubereats',
			'deliverect',
			'ladditionTicket',
			'misterBooking',
			'mewsSystems',
			'tabesto',
			'dood',
			'deliveroo',
		].includes(prefix)
	) {
		return 'partner.integration_guide_pdf.' + prefix;
	} else if (['eviivo'].includes(prefix)) {
		return 'partner.integration_guide_pdf.pms';
	} else return null;
};

export const isEmail = inputText => {
	var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
	if (inputText.match(mailformat)) {
		return true;
	} else {
		return false;
	}
};
