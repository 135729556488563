import { put, call, take, all, fork } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { I18n } from 'react-redux-i18n';
import { setUberEatsAuthorization } from '../../Containers/Partners/service/partnersConfig/partners/ubereats';
import { notify } from '../../Components/Notifier/services/saga';

function* handleRedirect() {
	while (true) {
		const { location } = yield take('HANDLE_REDIRECT');
		const [vide, redirect, route, action] = location.pathname.split('/');
		let path = route.split('+').join('/');
		if (route === 'partners') {
			yield put({ type: 'HANDLE_REDIRECT_PARTNER', partner: action, location });
			const { pathParams } = yield take('HANDLE_REDIRECT_PARTNER_FINISHED');
			path += pathParams;
		} else {
		}

		yield put(push('/' + path));
	}
}

function* handlePartnerRedirect() {
	const { partner, location } = yield take('HANDLE_REDIRECT_PARTNER');
	let pathParams = '';
	if (partner === 'ubereats') {
		if (location.search.includes('code=')) {
			let params = new URLSearchParams(location.search);
			const code = params.get('code');

			yield fork(setUberEatsAuthorization, code);
			pathParams = '?openPartner=ubereats';
		} else {
			yield call(
				notify,
				I18n.t('partner.ubereats_wrong_initialization') +
					' : MISSING UBEREATS CODE',
				'error',
				7000
			);
		}
	}
	yield put({ type: 'HANDLE_REDIRECT_PARTNER_FINISHED', pathParams });
}

export default function* indexSaga() {
	yield all([handleRedirect(), handlePartnerRedirect()]);
}
