import { take, put, call, race } from 'redux-saga/effects';
import { isPartner } from '../service/user/saga';
import { push } from 'connected-react-router';
import { UPDATE_USER } from '../service/user/types';
import { retrieveVariable } from '../service/fetcher.saga';

const ROOT = '/';
const access = {
	partner: ['eventWebhook'],
	other: ['partners', 'info', 'menu'],
};

export default function* RouterFlowSaga() {
	let userPartner = yield call(isPartner);
	while (true) {
		const { route, user } = yield race({
			user: take(UPDATE_USER),
			route: take('@@router/LOCATION_CHANGE'),
		});

		if (user) {
			userPartner = user.user.isPartner;
		} else {
			let pathname = route.payload.location.pathname;
			if (pathname != ROOT && !hasAccess(userPartner, pathname)) {
				yield call(redirectHome);
			}
			yield call(retrieveVariable);
			if (pathname.includes('/redirect')) {
				yield put({
					type: 'HANDLE_REDIRECT',
					location: route.payload.location,
				});
			}
		}
	}
}

function hasAccess(isPartner, pathname) {
	let accessPath = isPartner ? access.partner : access.other;
	return accessPath.some(path => pathname.includes(path));
}

function* redirectHome() {
	yield put(push('/'));
}
