import React from 'react';
import { Link } from 'react-router-dom';
import './style.scss';

export default ({ title, link, disabled }) => (
	<div className="dashboard-module">
		{disabled ? (
			<p className={'disabled'}>{title}</p>
		) : (
			<Link to={link}>
				<p>{title}</p>
			</Link>
		)}
	</div>
);
