//import menusSaga from "./menus/saga";
import comoSaga from './como';
import deliverectSaga from './deliverect';
import ladditionTicketSaga from './ladditionTicket';
import ubereatsSaga from './ubereats';
import doodSaga from './dood';
import tabestoSaga from './tabesto';
import koustSaga from './koust';
import barratioSaga from './barratio';
import CommandeEnLigneSaga from './CommandeEnLigne';
import ladditionMenuSaga from './ladditionMenu';
import misterBookingSaga from './misterBooking';
import eviivoSaga from './eviivo';
import mewsSaga from './mews';
import tickncook from './tickncook';
import pongo from './pongo';
import sunday from './sunday';
import obypay from './obypay';
import zerosix from './zerosix';
import deliveroo from './deliveroo';
import ladditionTapToPay from './ladditionTTP';
import dishop from './dishop';
import melba from './melba';
import rushour from './rushour';
import dionysols from './dionysol';

import { all, take, put, call } from 'redux-saga/effects';
import { PUT_CONFIG_PARTNER } from '../types';
import { notify } from '../../../../../Components/Notifier/services/saga';
import { I18n } from 'react-redux-i18n';
import { PARTNERS } from '../../../config';
import { push } from 'connected-react-router';
import { getPartnerRoute } from '../../../utils';
import { showConfirm } from '../../../../../Components/ConfirmRedux/service/saga';
function* configPartner() {
	while (true) {
		const { prefix, data } = yield take(PUT_CONFIG_PARTNER);
		let type = '';
		switch (prefix) {
			case 'como':
				type = 'PUT_CONFIG_COMO';
				break;
			case 'ubereats':
				type = 'PUT_CONFIG_UBEREATS';
				break;
			case 'deliverect':
				type = 'PUT_CONFIG_DELIVERECT';
				break;
			case 'ladditionTicket':
				type = 'PUT_CONFIG_LADDITIONTICKET';
				break;
			case 'dood':
				type = 'PUT_CONFIG_DOOD';
				break;
			case 'tabesto':
				type = 'PUT_CONFIG_TABESTO';
				break;
			case 'koust':
				type = 'PUT_CONFIG_KOUST';
				break;
			case 'barratio':
				type = 'PUT_CONFIG_BARRATIO';
				break;
			case 'celladdition':
				type = 'PUT_CONFIG_CELLADDITION';
				break;
			case 'ladditionMenu':
				type = 'PUT_CONFIG_LADDITION_MENU';
				break;
			case 'misterBooking':
				type = 'PUT_CONFIG_MISTERBOOKING';
				break;
			case 'eviivo':
				type = 'PUT_CONFIG_EVIIVO';
				break;
			case 'mewsSystems':
				type = 'PUT_CONFIG_MEWS';
				break;
			case 'tickncook':
				type = 'PUT_CONFIG_TICKNCOOK';
				break;
			case 'pongo':
				type = 'PUT_CONFIG_PONGO';
				break;
			case 'sunday':
				type = 'PUT_CONFIG_SUNDAY';
				break;
			case 'obypay':
				type = 'PUT_CONFIG_OBYPAY';
				break;
			case 'zerosix':
				type = 'PUT_CONFIG_ZEROSIX';
				break;
			case 'deliveroo':
				type = 'PUT_CONFIG_DELIVEROO';
				break;
			case 'ladditionTapToPay':
				type = 'PUT_CONFIG_TAPTOPAY';
				break;
			case 'dishop':
				type = 'PUT_CONFIG_DISHOP';
				break;
			case 'melba':
				type = 'PUT_CONFIG_MELBA';
				break;
			case 'rushour':
				type = 'PUT_CONFIG_RUSHOUR';
				break;
			case '1055':
				type = 'PUT_CONFIG_DIONYSOL';
				break;
			default:
				type = '';
		}
		if (type) {
			yield put({ type, data });
			const { step } = yield take(`${type}_FINISHED`);
			if (step === 'success') {
				const content = yield call(getSuccessConfigContent, prefix);
				if (PARTNERS[prefix] && PARTNERS[prefix].component && content) {
					yield put(push(getPartnerRoute(prefix)));

					yield call(showConfirm, content, 'info', {
						options: {
							title: I18n.t('partner.onboarding_success_config.title'),
						},
					});
				} else {
					yield call(
						notify,
						I18n.t('partner.onboarding_success'),
						'success',
						5000
					);
				}
			}
		}
	}
}

function getSuccessConfigContent(prefix) {
	const name = I18n.t('partner.prefix.' + prefix);
	switch (prefix) {
		case 'mewsSystems':
		case 'eviivo':
		case 'misterBooking':
			return I18n.t('partner.onboarding_success_config.pms', {
				partner_name: name,
			});
		case 'deliveroo':
		case 'celladdition':
		case 'deliverect':
		case 'ubereats':
		case 'otter':
		case 'rushour':
		case 'tabesto':
			return I18n.t('partner.onboarding_success_config.export', {
				partner_name: name,
			});
		case 'ladditionTicket':
			return;
		case 'ladditionTapToPay':
			return I18n.t('partner.tap_to_pay.success_popup');
		default:
			return I18n.t('partner.onboarding_success_config.default', {
				partner_name: name,
			});
	}
}

export default function* indexSaga() {
	yield all([
		configPartner(),
		comoSaga(),
		deliverectSaga(),
		ladditionTicketSaga(),
		ubereatsSaga(),
		doodSaga(),
		tabestoSaga(),
		koustSaga(),
		barratioSaga(),
		CommandeEnLigneSaga(),
		ladditionMenuSaga(),
		misterBookingSaga(),
		eviivoSaga(),
		mewsSaga(),
		tickncook(),
		pongo(),
		sunday(),
		obypay(),
		zerosix(),
		deliveroo(),
		ladditionTapToPay(),
		dishop(),
		melba(),
		rushour(),
		dionysols(),
	]);
}
