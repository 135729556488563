/**
 * Instructions
 *  - Renseigner les traductions par Module
 *  - Lorsque l'on rajoute une traduction, la rajouter également sur les autres fichiers de traductions (meme non traduite)
 */
export default {
	general: {
		save: 'Guardar',
		valid: 'Validar',
		confirm: 'Confirmar',
		cancel: 'Cancelar',
		yes: 'Sí',
		no: 'No',
		monday: 'Lun.',
		tuesday: 'Mar.',
		wednesday: 'Mie.',
		thursday: 'Jue.',
		friday: 'Vie.',
		saturday: 'Sáb.',
		sunday: 'Dom.',
		add: 'Añadir',
		edit: 'Modificar',
		deploy: 'Implementar',
		close: 'Cerrar',
		logout_confirm: '¿Está seguro de querer cerrar sesión?',
		loading: 'Cargando...',
		warning: 'Cuidado',
		delete: 'Supprimer',
		laddition: "L'Addition",
		i_get_it: 'Comprendo',
		help: 'Ayuda',
		contact: 'Contacto',
		documentation: 'Documentation',
		error: 'Se ha producido un error',
	},
	dashboard: {
		dashboard: 'Tablero',
		export: 'Mis exportaciones',
		infos: 'Mis datos',
		event_webhook: 'Eventos / Webhooks',
		partners: 'Mis socios',
		partner_export: 'Exportaciones de productos (tests)',
		menu: 'Menú digital',
	},
	info: {
		menu: 'Menú digital',
		title: 'Datos',
		description: 'Descripción',
		short_description: 'Descripción corta',
		about: 'Presentación',
		changements_success: 'Sus cambios se han realizado',
		remaning_caracters: 'Caracteres restantes',
		picture: {
			title: 'Imágenes',
			subtitle: 'Puedes añadir un archivo PDF o hasta 8 imágenes (max 20mo)',
			new_picture: 'Añadir una imagen',
			drag_drop_image: 'Arrastrar / Subir imagen',
			or: 'O',
			import: 'Importar',
			name: 'Nombre de la imagen',
			description: 'Descripción',
			confirm_delete: '¿Está seguro de querer eliminar esta imagen?',
			picture_porn:
				'Atención, la imagen que desea añadir tiene un contenido inapropiado. Por favor, inténtelo de nuevo',
			nsfw_check: 'Comprobando su imagen',
			bad_extension: 'Los ficheros de tipo %{extension} no están permitidos',
			too_heavy: 'El fichero es demasiado grande',
			max_size: 'Tamaño máximo:',
		},
		address: {
			address: 'Dirección',
			number: 'N°',
			street: 'Nombre de la vía',
			city: 'Localidad',
			zip_code: 'Código postal',
			name: 'Nombre comercial',
			state: 'Provincia',
			country: 'País',
			custom_information: 'Información complementaria',
			longitude: 'Longitud',
			latitude: 'Latitud',
			locate_button: {
				init: 'Localizar automáticamente en el mapa',
				progress: 'Localización en curso...',
				fail: 'Fallo: no se han encontrado coincidencias',
				success: 'Su establecimiento ha sido localizado correctamente',
			},
			location_missing_fields:
				'Debe introducir la dirección completa para iniciar la localización automática (Número y provincia son opcionales)\n',
			location_failed:
				'Su establecimiento no ha sido localizado, por favor, compruebe la dirección. También puede mover el puntero de forma manual.',
			choose_location: 'Por favor, seleccione una dirección',
		},
		dates: {
			name: 'Nombre',
			open_time: 'Franjas horarias',
			start_date: 'Fecha de inicio',
			end_date: 'Fecha de fin',
			all_day: 'Todo el día',
			start: 'Inicio',
			end: 'Fin',
			days: 'Días',
			reserv_dates: 'Horarios de apertura',
			noon: 'Mediodía',
			evening: 'Noche',
			close_period: 'Periodo(s) de cierre',
			add_closing_date: 'Añadir una fecha de cierre',
			error_double_closing_date: 'Ya ha guardado este período de cierre',
			confirm_delete:
				'¿Está seguro de que quiere eliminar este período de cierre ?',
		},
	},
	menuOnline: {
		loadQrcode: 'Descargar Qrcode',
		goto: 'Ver',
	},
	binding: {
		button: 'Asociar',
		button_label_price: 'categorías de precio',
		button_label_payment: 'pagos',
		button_label_table: 'salas',
		title: 'Asociaciones',
		select_payment_type: 'Método de pago',
		select_floor: 'Sala',
		select_price_category: 'Categoría de precio (opcional)',
		none: '- Seleccionar -',
		help:
			'En la aplicación y en el panel de informes, todos los pedidos grabados mediante el partenario se asociarán automáticamente a:',
		empty_payment_type: 'Ningún método de pago',
		empty_floor: 'Ninguna sala',
		empty_price_category: 'Ninguna categoría de precio',
	},
	product_quantity: {
		button: 'Modificar el umbral (Existencias)',
		title: 'Modificación del umbral de productos (existencias)',
		help:
			'Permite desactivar automáticamente en el Click & Collect los productos cuyo numero sea inferior a esta cantidad. Los ajustes se realizan automáticamente a lo largo del servicio, en función de las existencias en el iPad.',
	},
	upload: {
		fail: 'Se ha producido un error al subir su imagen',
		error_code: {
			LIMIT_FILE_SIZE: 'El fichero que intenta cargar es demasiado grande',
		},
	},
	webhook: {
		categories: {
			all: 'Todos los eventos',
		},
		no_event: 'Ningún evento',
		confirm_delete_param: '¿Está seguro de querer eliminar esta configuración?',
		delete_confirm:
			'¿Está seguro de querer reiniciar su Hook ? Esta acción es irreversible.',
		name: {
			partnerRequestGetOrderLive: 'Recuperar un pedido (en el iPad)',
			partnerRequestGetOrder: 'Recuperar un pedido (en la API)\n',
			partnerRequestGetStore: 'Recuperar la información del restaurante',
			partnerRequestGetOrders: 'Recuperar los pedidos (en la API)',
			partnerRequestGetUsers: 'Recuperar los usuarios de la aplicación\n',
			partnerRequestGetProducts: 'Recuperar los productos',
			partnerRequestGetMenu: 'Recuperar los productos del menú principal',
			partnerRequestGetExtras: 'Recuperar los suplementos',
			partnerRequestPostPaymentLive:
				'Añadir un nuevo método de pago en el iPad',
			partnerRequestPostMediationLive:
				'Añadir nuevos datos en el centro de mediación',
			partnerRequestPostOrderLive: 'Nuevo pedido en el iPad',
			prPostCancelOrderLive: 'Cancelar un pedido en el iPad',
			apiDataRemovePaymentLine: 'Borrar un pago del iPad',
			apiDataValidationPaymentLine: 'Validación del estado de un pago del iPad',
			apiDataOpenShift: 'Apertura del servicio',
			apiDataCloseShift: 'Cierre del servicio',
			apiDataUpdateOrder: 'Actualizando un pedido',
			apiDataUpdateOrderStatus: 'Actualizando el estado de un pedido',
			apiDataUpdateQuantity: 'Actualizando la cantidad',
			stockAvailable: 'Existencias disponibles',
		},
		availableParams: {
			relatedUserExternalId: 'Identificador del restaurante asociado',
			partnerTokenOut: 'Token del socio',
			customerId: 'Identificación del socio',
		},
	},
	partner: {
		title: 'Socios',
		active: 'Activo',
		inactive: 'Inactivo',
		tooltip_partner_info: 'ID de socio y token',
		tooltip_partner_edit: 'Configurar el socio',
		tooltip_partner_help: 'Necessita ayuda ?',
		token_id_customer_undefined: 'Indisponible',
		infoUndefined: 'Non définie',
		put_active: 'Activar',
		search: 'Encuentra un socio...',
		link_espace_client: 'Dashboard',
		soon: '¿Mis futuros socios?',
		configuration: 'Configurar',
		configuration_in_progress: 'En configuración',
		continue_configuration: 'Continuar la configuración',
		begin_configuration: 'Iniciar configuración',
		more_info: 'Más información',
		unlock: 'Desbloquear',
		call_partner_support:
			"Póngase en contacto con su socio o con el servicio de asistencia de L'Addition para obtener más información",
		config_error: {
			title: 'Se ha producido un error',
			partner_config_missing: 'Este socio no está gestionado',
			auth_linking: 'Problema de vinculación de la pareja <> Adición',
			init_partner: 'Problema de inicialización del socio',
		},
		configuration_connector: {
			wait_title: 'Su petición ha sido tenida en cuenta.',
			wait_label:
				'Después de firmar el presupuesto, vuelva aquí para configurar el conector.',
			wait_label_manual_onboarding:
				'Tras firmar el presupuesto, nuestros equipos configurarán su conector',
			payment_method:
				'Elige un método de pago para empezar a activar el conector',
			pay_cb: 'Pago CB: %{price}',
			need_contact: 'Para ser contactado por nuestros equipos',
			redirect: 'Será redirigido a la interfaz de pago',
		},
		label_lead_partner: '¿Aún no tiene %{partner} cuenta?',

		prospect_lead:
			'¿Le gustaría que le llamara el departamento de ventas del socio?',
		lead_partner_notif_ok:
			'El servicio %{partner} ha sido notificado. Nos pondremos en contacto con usted lo antes posible',
		lead_partner_notif_ko:
			'Se ha producido un error. Por favor, inténtelo de nuevo, o póngase en contacto con %{partner} directamente',
		connector_confirm:
			'¿Desea que informemos al departamento de ventas de su interés por este conector?  Le llamaremos lo antes posible',
		connector_confirm_ok:
			'Se ha avisado al departamento de ventas. Nos pondremos en contacto con usted lo antes posible',
		connector_confirm_ko:
			'No se ha podido avisar al departamento de ventas. Por favor, inténtelo de nuevo o póngase en contacto con ellos directamente',
		reminder: 'Recordatorio',
		enter_key_at_partner: 'Comunique la clave al socio',
		enter_token_at_partner: 'Ingrese la clave en la interfaz del socio',
		waiting_for_partner: '¿La clave está registrada en la interfaz del socio?',
		active_link_ipad: 'Activar el enlace en la aplicación',
		link_with_partner_missing:
			'No se pudo confirmar el enlace con el socio. Compruebe que la clave se haya registrado en la interfaz del socio o póngase en contacto con el soporte del socio.',
		link_with_partner_ok: 'Se ha confirmado el vínculo con el socio',
		check_overpaid:
			'Asegúrese de que la opción "Convertir los pagos en exceso en propinas" de su aplicación está activada',
		update_nuc: 'Necesita actualizar su servidor la Adición',
		connexion_with_partner_missing:
			'No se pudo confirmar la conexión con el socio. Compruebe que ha activado la conexión con el socio en la aplicación L’Addition, y que su iPad está encendido y la aplicación abierta',
		using_ipad:
			'Parece que estás utilizando un iPad para completar el enlace con la aplicación Addition. La prueba de conectividad debe activarse desde otro dispositivo (PC u otra tableta) para confirmar el enlace. Si tienes más de un iPad, puedes activar la prueba desde uno de ellos.',
		generate_token: 'Haga clic aquí para generar la clave',
		copy_token: 'Copie la clave',
		token_copied: 'Clave copiada',
		check_link_partner_to_customer: 'Compruebe la conexión',
		check_connexion_with_partner: 'Compruebe la conexión con el socio',
		check_connexion: 'Verifica la conexión',
		partner_ok: 'La conexión con el socio se ha configurado correctamente',
		ladditionMenu_accessAdminMenu_missing:
			'Su cuenta no está preparada para usar la tarjeta en línea, por favor contacte con el soporte técnico',
		validation_partner_checking_error:
			'Actualice todos sus dispositivos desde la AppStore',
		check_uuid: 'Verifica la uuid',
		ubereats: {
			url_signup: 'https://www.ubereats.com/restaurant/es-ES/signup',
			label_login: 'Acceder a Uber Eats',
			label_signup: '¿Aún no tiene cuenta? Cree su cuenta aquí',
		},
		missing_uuid: 'Ingresa tu uuid de Uber Eats',
		set_uuid: 'Registra la uuid de tienda de Uber Eats',
		documentation: 'Configuración de la carta de l’Addition para %{partner}',
		product_id_at_partner:
			'Ingresa los códigos de artículo de L’Addition en la interfaz de Uber Eats.',
		check_if_product_id:
			'Verifica que los códigos de artículo de L’Addition estén correctamente especificados para cada artículo de Uber Eats.',
		check_if_product_id_ok: 'Verificar',
		check_product_incomplete:
			'Completa los códigos que faltan en la interfaz de Uber Eats y luego presiona el botón "Verificar" nuevamente.',
		check_product_empty:
			'¿Ha seguido el procedimiento correctamente e ingresado los códigos de artículo de LAddition en la interfaz de Uber Eats?',
		wrong_correspondance_id:
			"Algunos artículos sobre Uber Eats no tienen un código de artículo de L'Addition, complételos.",
		wrong_uuid:
			'La verificación de uuid falló, contacte con su administrador de cuenta o con el servicio de asistencia de Uber Eats (dos posibles razones: uuid incorrecto o enlace inactivo en el lado de Uber Eats).',
		uuid_correct: 'Tu uuid de Uber Eats ha sido registrado',
		wrong_option_partner:
			'Se produjo un error al recuperar las opciones específicas de la operación del socio.',
		wrong_activation_desactivation:
			"Une erreur est survenue lors de l'activation/désactivation du partenaire",
		wrong_initialization:
			'ha ocurrido un error al activar la cuenta con el socio',
		ubereats_wrong_initialization:
			'Se ha producido un error al autorizar tu cuenta de Uber',
		ubereats_success_initialization:
			'Accede a tu cuenta de UberEats OK. Puede continuar con su incorporación',
		ubereats_wrong_provisioning:
			'Se ha producido un error durante la selección del establecimiento',
		ubereats_redirection:
			'Serás redirigido a la interfaz de inicio de sesión de Uber Eats',
		ubereats_no_store:
			'Falta configuración de stores. Por favor, completa tu configuración en el espacio Uber Eats.',
		external_config_redirection: 'Será redirigido a la interfaz de socios',
		wrong_desactivation:
			'Ha ocurrido un error al desactivación la cuenta con el socio',
		missing_id_list:
			"Algunos artículos de Uber Eats no tienen un código de artículo en L'Addition",
		empty_id_list:
			"Ningún artículo de Uber Eats tiene un código de artículo en L'Addition",
		right_correspondance_id:
			'La verificación de los códigos de artículo es correcta',
		ipad_not_live:
			'El iPad debe estar conectado, Addition abierto, los módulos Partner activados (Ajustes -> Integraciones Partner -> Módulos Partner activados) en el iPad maestro',
		must_sync_ipad:
			'Para completar la configuración de su conector, realice una sincronización (Configuración -> Sincronización manual) y compruebe el estado del botón "Activar módulos de socios" (Configuración -> Integraciones de socios).',
		download_guide: 'Descargar la guía (PDF)',
		integration_guide_pdf: {
			celladdition:
				'https://cdn.laddition.com/7/laddition-et-clickandcollect-guide-integration.pdf',
			ubereats:
				'https://cdn.laddition.com/7/laddition-y-ubereats-procedimiento-de-integracion.pdf',
			deliverect:
				'https://cdn.laddition.com/7/laddition-et-deliverect-guide-integration.pdf',
			ladditionTicket:
				'https://cdn.laddition.com/7/laddition-et-deliverect-guide-integration.pdf',
			pms: 'https://cdn.laddition.com/27/integration-laddition-pms.pdf',
			mewsSystems:
				'https://cdn.laddition.com/27/integration-laddition-mewssystems.pdf',
			misterBooking:
				'https://cdn.laddition.com/27/integration-laddition-misterbooking.pdf',
			tabesto: 'https://cdn.laddition.com/27/integration-laddition-tabesto.pdf',
			dood: 'https://cdn.laddition.com/27/integration-laddition-dood.pdf',
		},
		confirm_didnt_read_doc_yolo:
			'Advertencia: esta documentación te guiará hasta la exportación final de tu mapa, ¿estás seguro de que quieres continuar sin descargar la documentación?',
		select_store: 'Seleccione su restaurante',
		partial_title: '- Está es la lista de códigos faltantes en Uber Eats:',
		choose_room:
			"Elija una sala donde los pedidos del socio se registrarán en l'Addition.",
		choose_room_cmd_en_ligne:
			'Elija una sala en la que se registrarán los pedidos en línea en L’Addition',
		choose_paiement_ticket: 'Elige un medio de pago',
		choose_paiement:
			'Elige un medio de pago con el que se registrarán los pedidos del socio en L’Addition.',
		choose_paiement_cmd_en_ligne:
			'Elija un medio de pago con el que se registrarán los pedidos online en L’Addition',
		choose_priceCategory:
			'Elige una categoría de precios con la que se registrarán los pedidos del socio en L’Addition (OPCIONAL)',
		choose_priceCategory_cmd_en_ligne:
			'Elija una categoría de precios con la que se registrarán los pedidos en línea en L’Addition (OPCIONAL)',
		paiement_empty: 'Especifique un medio de pago',
		room_empty: 'Por favor especifique una sala',
		actives_product: 'Productos disponibles',
		suspends_product: 'Productos no disponibles',
		description_menu_selector: 'Seleccione una carta:',
		nb_product: 'Número de producto:',
		type_carte: 'Tipo de carta:',
		primary: 'Permanente',
		secondary: 'Secundario',
		begin: 'Comenzar',
		init_liaison: 'Inicializar la configuración del pedido online',
		configuration_guide: 'Continuar con la configuración del pedido en línea',
		must_do_export:
			'Para finalizar la reactivación de Uber Eats, debe generar una exportación',
		como_input_pos_external_id: 'Por favor, rellene el Como ID <> LAddition',
		como_input_joining_item:
			'Por favor, rellene el punto de unión (Joining Item)',
		como_input_api_key: 'Por favor, introduzca la clave API de Como',
		como_label_link_auth: "Enlace entre la l'Addition y Como",
		dood_id_cliente: 'Por favor, introduzca el cliente id',
		deliverect_input_location_id:
			'Por favor, rellene el locationID (proporcionado por Deliverect)',
		deliverect_input_account_id:
			'Por favor, rellene el accountID (proporcionado por Deliverect)',
		deliverect_check_link: 'Verificación',
		deliverect_unlink_partners:
			'Actualmente tienes una configuración directa con Deliveroo y/o UberEats. Desea desactivar estos conectores para dejar espacio a Deliverect para unirlos?',
		input_set_customer_id:
			'Por favor, introduzca la clave/client id proporcianado por el socio',
		step: {
			step: 'Paso',
			token_copy: 'Copia tu token',
			next_step: 'Siguiente paso',
			finish: 'Terminado',
		},
		api_key_verified: 'Clave API verificada',
		misterBooking_credientials_verified: 'Hotel ID verificada',
		eviivo_credentials_verified: 'PropertyShortName verificada',
		deliverect_credientials_verified: 'Account/Location ID verificada',
		dood_customer_id:
			'Por favor, introduzca su ID cliente (proporcionado por Dood)',
		tabesto_credientials_verified:
			'Identificación del cliente Tabesto verificada',
		token_tabesto: 'Por favor, introduzca el token de Tabesto',
		token_koust: 'Por favor, introduzca el token de Koust',
		token_barratio: 'Por favor, introduzca el token de Barratio',
		misterbooking_customer_id:
			'Por fabor, introduzca su Hotel ID (proporcionado por MisterBooking)',
		eviivo_customer_id:
			'Por fabor, introduzca su PropertyShortName (proporcionado por Eviivo)',
		mews_customer_id:
			'Por fabor, introduzca su Access Token (proporcionado por Mews)',
		tickncook_customer_id:
			'Por fabor, introduzca su Account ID (proporcionado por TickNCook)',
		generated_customer_id: 'ID de socio generado',
		put_customer_id_at_partner:
			'Por favor, introdúzcalo en la interfaz del socio para completar la integración',
		onboarding_common_errors: {
			general:
				'Se ha producido un error al conectar con el interlocutor. Por favor, inténtalo de nuevo. Póngase en contacto con el servicio técnico si el problema persiste.',
			set_auth_link: 'Se ha producido un error al enlazar con el socio',
			check_customer_id:
				'No se ha podido verificar la identificación/la clave del cliente',
			token_generation:
				'Se ha producido un error al generar el token de acceso, compruebe su token de usuario.',
			set_entities:
				'Se ha producido un error al generar del método de pago/Catégoria de precio/sala en el iPad',
			set_stock_online: 'Se ha producido un error al configurar la cuenta',
			input_validator:
				'El formato del texto no es el esperado. ¿Está seguro de que ha rellenado correctamente el campo requerido?',
		},
		onboarding_como_errors: {
			set_branch_pos_id:
				'Se ha producido un error al generar su ID de fidelidad',
			set_api_key:
				'Se ha producido un error al comprobar la conexión de la API, compruebe la clave',
			set_liaison_como: 'Se ha producido un error al enlazar con Como',
			set_liaison_webview_loyalty:
				'Se ha producido un error al enlazar con el conector de fidelidad la Adición',
			set_api_discount:
				'Se ha producido un error al configurar los descuentos en el iPad',
			set_api_payment_type:
				'Se ha producido un error al configurar el método de pago en el iPad',
		},
		onboarding_deliverect_errors: {
			set_location_id: 'Se ha producido un error al registrar el location ID',
			set_account_id: 'Se ha producido un error al registrar el account ID',
			check_acount_location:
				'Se ha producido un error al comprobar su location ID / Account ID, por favor, vuelva a introducirla.',
			set_variable_config_channel:
				'Se ha producido un error al comprobar su identificación de cuenta/ubicación, por favor, vuelva a introducirla.',
			set_liaison_deliverect:
				'Se ha producido un error al enlazar con Deliverect',
			mandatory_field: 'Este campo es obligatorio',
			set_table_and_paiement:
				'Ocurrió un error al asignar su método de pago y habitación predeterminada',
			same_account_location_id:
				'El location_id y el account_id no pueden ser idénticos. Por favor, vuelva a escribirlos',
		},
		onboarding_ladditionticket_errors: {
			set_customer_id:
				"Une erreur est survenu lors de l'enregistrement de l'identifiant client",
			call_laddition_ticket:
				'Une erreur est survenue pendant la connexion avec le partenaire',
			set_api_variables:
				"Une erreur est survenu lors de l'initialisation des variables de fonctionement",
		},
		onboarding_tabesto_errors: {
			set_table_payment_and_price_category:
				'Se ha producido un error al asignar la forma de pago / habitación / categoría de precio por defecto',
		},
		onboarding_misterBooking_errors: {
			check_hotel_id:
				'Se ha producido un error al comprobar su Hotel ID, por favor, vuelva a introducirla.',
		},
		onboarding_eviivo_errors: {
			check_customer_id:
				'Se ha producido un error al comprobar su PropertyShortName por favor, vuelva a introducirla.',
		},
		mandatory_field: 'Este campo es obligatorio',
		link_partner_customer_allready_exist:
			'Ya existe un enlace con este locationID y el socio. Póngase en contacto con el servicio de atención al cliente del socio.',
		onboarding_success: 'El enlace con su pareja se ha iniciado correctamente',
		popupExternalId: 'Identificador que se transmitirá al interlocutor',
		authenticationToken: 'Clave de autenticación',
		popupInfo: {
			external_id: 'Identificador del socio',
			token: 'Clave de autenticación',
			account_id: 'Account id',
			location_id: 'Location id',
			token_barratio: 'Clave Barratio',
			koust_token: 'Clave Koust',
			kitchen_id: 'Kitchen id',
		},
		update_nuc_ignored:
			'Licencia única detectada - Paso 2: Actualizar servidor Adición ignorada.',
		prefix: {
			guest: 'Invitado online',
			netresto: 'Mycawan',
			fourch: 'El Tenedor',
			zen: 'Zenchef',
			bts: 'BtsVendee',
			shine: 'Shine',
			ciss: 'Ciss',
			pmt: 'Pay My Table',
			beefhouse: 'Beef House',
			mediation: 'Mediation',
			bfast: 'Bfast',
			celladdition: 'El pedido online',
			tgpl: 'The Good Place',
			gstock: 'GStock',
			deliveroo: 'Deliveroo',
			easilys: 'Easilys',
			ubereats: 'Uber Eats',
			snapshift: 'Combo',
			deliverect: 'Deliverect',
			ladditionTicket: 'Ticket / Paiement à table',
			dood: 'DOOD',
			tabesto: 'Tabesto',
			barratio: 'Barratio',
			koust: 'Koust',
			mewsSystem: 'Mews System',
			misterBooking: 'Mister Booking',
			bardistrib: 'Bar Distribution',
			eviivo: 'Eviivo',
			yokitup: 'Yokitup',
		},
		option: {
			title: 'Configuración del iPad',
			event: 'Actividad',
			empty: 'No hay opciones en la lista',
			advanced_settings: 'Gestión avanzada',
			simple_settings: 'Gestión sencilla',
			need_update: 'Es necesario actualizar la aplicación',
			category: {
				ticket: 'Recibido',
				manufacturing: 'Orden de producción',
				notification: 'Notificación',
				order: 'Pedido',
				price: 'Precio',
				other: 'Otros',
			},
			name: {
				autoOrderClosed: 'Cierre del pedido a la recepción',
				autoPrintManufacturing:
					'Impresión automática de la orden de elaboración',
				autoPrintManufacturingPaid:
					'Impresión automática de la orden de elaboración (con pagos)',
				autoPrintManufacturingUnPaid:
					'Impresión automática de la orden de elaboración (sin pago)',
				table_autoPrintManufacturing:
					'(Mesa) Impression automatique du bon de fabrication',
				table_autoPrintManufacturingPaid:
					'(Mesa) Impresión automática de la orden de elaboración (con pagos)',
				table_autoPrintManufacturingUnPaid:
					'(Mesa) Impresión automática de la orden de elaboración (sin pago)',
				autoPrintReceipt: 'Impresión automática del ticket',
				autoPrintReceiptPaid: 'Impresión automática del ticket (con pagos)',
				autoPrintReceiptUnPaid: 'Impresión automática del ticket (sin pago)',
				table_autoPrintReceipt: '(Mesa) Impresión automática del ticket',
				table_autoPrintReceiptPaid:
					'(Mesa) Impresión automática del ticket (con pagos)',
				table_autoPrintReceiptUnPaid:
					'(Mesa) Impresión automática del ticket (sin pago)',
				checkStock: 'Comprobación del stock antes confirmar un pedido',
				showNotification: 'Notificación visual en la recepción',
				showNotificationPaid: 'Notificación visual en la recepción (con pagos)',
				showNotificationUnPaid:
					'Notificación visual en la recepción (sin pago)',
				table_showNotification: '(Mesa) Notificación visual en la recepción',
				table_showNotificationPaid:
					'(Mesa) Notificación visual en la recepción (con pagos)',
				table_showNotificationUnPaid:
					'(Mesa) Notificación visual en la recepción (sin pago)',
				playNotificationSound: 'Notificación sonora en la recepción\n',
				playNotificationSoundPaid:
					'Notificación sonora en la recepción (con pagos)',
				playNotificationSoundUnPaid:
					'Notificación sonora en la recepción (sin pago)',
				table_playNotificationSound:
					'(Mesa) Notificación sonora en la recepción',
				table_playNotificationSoundPaid:
					'(Mesa) Notificación sonora en la recepción (con pagos)',
				table_playNotificationSoundUnPaid:
					'(Mesa) Notificación sonora en la recepción (sin pago)',
				printTicketComment: 'Imprimir el comentario del cliente en el ticket',
				realPrice:
					'Integración de la facturación (precios reales de los productos)',
				manualCancel: 'Cancelación manual de pedidos',
				showPaymentStatus: 'Mostrar el icono de estado del método de pago',
				autoOrderClosedPaymentLine:
					'Cerrar automáticamente la orden si está cerrada',
				autoPrintReceiptPaymentLine:
					'Impresión automática del recibo si el pedido se agota',
				showNotificationPaymentLine: 'Notificación si el pedido se ha agotado',
				table_autoOrderFusion: 'Transferencia a las mesas abiertas',
				table_autoOrderFusionPaid:
					'Transferencia a las mesas abiertas (con pago) ',
				table_autoOrderFusionUnPaid:
					'Transferencia a las mesas abiertas (sin pago)',
				table_autoForceDirect: 'Agregar todos los productos en vivo',
				checkExactTableName:
					'Inspeccione el nombre exacto de la tabla para encontrar su identificador',
				table_checkExactTableName:
					'Inspeccione el nombre exacto de la tabla para encontrar su identificador',
				autoPrintPicking: 'Impresión del ticket de montaje',
				autoPrintPickingPaid:
					'Impresión del albarán de montaje (pedidos pagados)',
				autoPrintPickingUnpaid:
					'Impresión de nota de montaje (pedidos impagados)',
			},
		},
		configuration_module: {
			title_cc: 'Configuración del módulo',
			title: 'Configuración de la integración',
			guide: 'Guía (PDF)',
			link_admin_cc: 'Ir a la interfaz de configuración',
		},
		parameter: 'Configuración',
		param_description:
			'Los parámetros son variables disponibles que usted puede integrar en la url, las cabeceras o el cuerpo.',
		variable: 'Variable',
		variable_description: 'Descripción',
		url_tester_description:
			'Por favor, compruebe su URL, recibirá un código con el tag "x-laddition-token-webhook". Copie este código.',
		to_test: 'Prueba',
		token: 'Token',
		invalid_url: 'La dirección URL no ha sido validada. Por favor, compruébela',
		invalid_token: 'El token no es válido, por favor, inténtelo de nuevo.',
		valid_token: 'El token ha sido validado',
		error_test_request:
			'Ha ocurrido un error durante la búsqueda, por favor, compruebe la URL y comience de nuevo.',
		success_test_request:
			'Su prueba ha sido enviada, por favor, recupere el token y especifíquelo en el campo necesario.',
		button_delete: 'Reiniciar',
		headers: 'Encabezado',
		params: 'Cuerpo',
		method: 'Método',
		webhook: 'Webhook',
		url: 'Url',
		key: 'Clave',
		value: 'Valor',
		error_param: 'El parámetro "%{param}" no existe.',
		error_param_url: 'Los parámetros indicados no existen',
		cateogies_active: 'Categorías activadas',
		categorie_inactive: 'Categorías desactivadas',
		products_selector: {
			title: 'Importar productos',
			select_menu: 'Seleccionar un menú',
			import_products: 'Importar productos',
			no_selection: 'Ningún elemento seleccionado',
			select_all: 'Seleccionar todo',
			deselect_all: 'Deseleccionar todo',
			section_list: {
				title: 'Categorías',
				none: 'Ninguna otra sección',
				all: 'Todos los productos',
				no_category: 'Sin categoría',
				menus: 'Menús',
			},
			selected_list: {
				title: 'Selección',
				no_selection: 'Ningún producto seleccionado',
				x_items_selected: '+ %{number} elemento(s) seleccionado(s)',
				unknow_menu: 'Menú',
			},
			selectionable_list: {
				search: 'Buscar un producto',
				title: 'Productos',
				no_selection: 'Ningún producto en esta sección',
				no_product: 'Ningún producto',
			},
			menu_select_reset_select:
				'¿Está seguro de querer cambiar su menú? Su selección actual se reiniciará',
		},
		productForm: {
			productSelected: 'Producto seleccionado',
			vat: 'IVA',
			vat_undefined: 'No definida',
			confirm_delete_image: '¿Está seguro de querer eliminar esta imagen?',
			name: 'Nombre',
			visible: 'Visible',
			notVisible: 'Invisible',
			description: 'Descripción',
			price: 'Precio',
			active: 'Activado',
			import: 'Importar',
			image: 'Añadir una imagen',
			supplement: 'Gestionar los suplementos',
			supplement_button: 'Gestionar los suplementos e información adicional',
			empty: 'No tiene ningún suplemento configurado',
			back: 'Volver',
			category_supplement_disabled:
				'Ve a "Editar extras" para cambiar el nombre, el precio y el tipo de extras. Las categorías y los suplementos en gris están desactivados por defecto.',
			take_away: 'Para llevar',
			locally: 'Para tomar en el local',
			tooltip_visible:
				'Visibilidad: el producto seguirá siendo visible en el mapa pero estará desactivado',
			tooltip_product_in_menu_category_supplement: 'Gestionar los suplementos',
		},
		supplements: {
			manage: 'Modificar los suplementos',
			name: 'Nombre',
			type: 'Tipo',
			price: 'Precio',
			supplements: 'suplementos',
			reset: 'Poner a 0',
			reset_confirm:
				'¿Está seguro de querer restaurar los valores por defecto de esta categoría de suplementos? Los nombres, tipos y precios de los suplementos volverán a su valor original.',

			remove_supplement_extra_confirm:
				'Esto restablecerá las categorías de suplementos por defecto para este producto. ¿Está seguro de que quiere realizar esta acción? ',
			types: {
				1: 'Una opción',
				2: 'Varias opciones',
				3: 'Varias opciones en cantidad',
			},
			from: 'De',
			to: 'a',
			maximum_choices: 'Máximo número de opciones',
			required: 'Obligatoria',
			empty: 'No hay categoría de suplementos para esta carta',
			empty_menu: 'No se ha configurado ninguna categoría de suplementos',
			no_select_menu: '- Seleccionar -',
			description_1:
				'Puede modificar los suplementos de su carta para adaptarlos al pedido en línea (nombre, tipo, precio). Seleccionar una carta para empezar.',
			alert_extra_prefix:
				'Los cambios realizados para este socio no afectan a los demás socios',
		},
		export: {
			confirm_delete: '¿Está seguro de que quiere eliminar esta exportación ?',
			confirm_export: '¿Está seguro de que quiere continuar?',
			active: 'Activo',
			see_more: 'Elige otra exportación',
			list: 'Mis exportaciones',
			unamed: 'Exportación sin título',
			loading: 'Cargando los datos de los productos...',
			generate_success: 'Exportación generada con éxito',
			export_fail_empty: 'No puede exportar un menú vacío',
			generate_fail: 'Se ha producido un error',
			generate_fail_generation:
				'Se ha producido un error al generar la exportación',
			generate_fail_notif:
				'Su exportación ha sido generada pero su socio no ha podido ser notificado',
			incompleted_product:
				'Algunos productos no están completos, por lo que no pueden ser integrados:',
			new_export: 'Nueva exportación',
			new_export_from_another_export: 'Nueva exportación de...',
			copy_of: 'Copia de',
			select_export: '-- Seleccionar una exportación --',
			select_export_empty:
				'Por favor, seleccione una exportación de la lista desplegable',
			export_error: 'Ha ocurrido un error',
			no_modification:
				'No ha habido ninguna modificación desde la última exportación, ¿está seguro de que quiere reexportar?',
			menu_configuration: 'Configuración del menu',
			activate_link_ubereats:
				'¿Quieres habilitar Uber Eats para recibir pedidos en L’Addition?',
			date_last_deployment: 'Fecha de la última implementación',
			date_last_deployment_to: 'a las',
			helper_no_export:
				'Pour que vos clients puissent passer des commandes via ce partenaire, vous devez préparer votre export de carte. Cliquez sur "Nouvel export" pour commencer. ',
			helper_not_exported:
				'Vous pouvez a tout moment modifier les sections et produits de votre export. N\'oubliez pas de cliquer sur le bouton "Déployer" pour envoyer votre export de carte au partenaire.',
			deliveroo_export_info:
				'Desplegar tu tarjeta puede desactivar las ofertas configuradas en tu centro Deliveroo. Recuerda comprobar tus ofertas en tu centro Deliveroo y reactivarlas desde la pestaña Marketer después de desplegar tu tarjeta.',
			deliveroo_info_1_min:
				'Tenga en cuenta que la API de Deliveroo está limitada a 1 exportación/minuto.',
		},
		general_options: {
			button: 'Options générales',
			title: 'Réglages partenaire généraux',
			ipad_changes: {
				title: "Mise à jour des produits depuis l'iPad",
				description:
					"Vous pouvez configurer les regles de mise à jour de vos export lorsque des produits et suppléments sont synchronisés depuis l'iPad. Si ces options sont cochés. Chaque modification remontée mettra à jour votre export chez vos partenaires de commandes en ligne.",
				label_product_name: 'Mise à jour nom',
				label_product_price: 'Mise à jour prix',
			},
		},
		brandChannel: {
			error_empty:
				'Configuración imposible, no se encontró ningún canal. Por favor recarga la página',
			title: 'Marcas / Canales',
			title_brand: 'Marcas',
			title_channel: 'Canales',
			select_placeholder: 'Selection ...',
			brand_channels: 'Canales específicos',
			add_channel: 'Agregar canal específico',
			channel_name: 'Apellido',
			channel_floor: 'Salón',
			channel_payment_type: 'Modo de pago',
			channel_price_category: 'Categoría de precio',
			mandatory_field: 'Este campo es obligatorio',
			brand_tooltip:
				'Una marca o cocina oscura corresponde a la asociación habitación / pago / categoría de precio necesaria para la inyección de ventas en el sistema de caja para un canal determinado (para diferenciar los artículos que se ofrecen en la tienda y los que solo están disponibles en línea)',
			channel_tooltip:
				'Un canal o plataforma corresponde a la asociación habitación/pago/categoría de precio necesaria para inyectar ventas en el sistema de pago (para diferenciar UberEats, Deliveroo, etc.)',
		},
		ladditionTicket: {
			activate_payplus: "Activar el enlace L'Addition Pay+",
			payplus_activated: "El enlace L'Addition Pay+ está activo",
			payplus_activation_fail:
				"La activation del enlace L'Addition Pay+ no sé passa bien",
		},
		misterBooking_paymentType: {
			CB: 'Tarjeta de débito',
			ESP: 'Efectivo',
			CHQ: 'Consulte',
			TICKET_RESTAURANT: 'Vales de comida',
			AMEX: 'Amex',
			VIR: 'Transferencia',
			PERTE: 'Pérdida',
			CHQVAC: 'Bonos de vacaciones',
			BON_CADEAU: 'Cheque regalo',
			CHL: 'Bonos logis',
		},
		externalData: {
			load_error:
				'No se han podido cargar los datos. Por favor, inténtelo de nuevo',
			info:
				"Para que la configuración sea óptima, debe vincular %{entity} de L'Addition a un equivalente de su socio. De este modo, los pedidos realizados se registrarán correctamente con su socio.",
			info_entity: {
				paymentType: 'todos los métodos de pago',
				floor: 'todas las salas',
				priceCategory: 'todas las categorías de precios',
				productType: 'todos los typo de productos',
				productId: 'los productos que lo quieres',
			},
		},
		mewsSystems: {
			serviceInputDescription:
				"Introduzca el servicio de Mews que se vinculará al sistema L'Addition",
			serviceInputLabel: 'Servicio',
			outletInputDescription:
				"Introduzca el punto de venta de Mews que se vinculará al sistema L'Addition",
			outletInputLabel: 'Salida',
			paymentInputDescription:
				"Introduzca el método de pago de Mews que se vinculará por defecto al sistema de L'Addition",
			paymentInputLabel: 'Forma de pago',
			dataEmpty: 'Lista de puntos de venta/servicios/pagos vacíos de Mews',
			refreshData: 'Refrescar datos',
			documentationDescription:
				'Parece que algunos de los datos de tus sistemas Mews están vacíos. ¿Los llenaste bien? Si es así, haga clic en el botón "Actualizar datos de Mews". Si no, gracias por consultar la documentación de los sistemas Mews a continuación',
			documentationCreateService: 'Crear un servicio',
			documentationService: 'Comprender los servicios en torno al POS',
			documentationAccounting: 'Contabilidad',
			settingsDefault: 'Configuración salida / servicio / y pago por defecto',
			settingsPayments: 'Vinculación de los métodos de pago',
			settingsProductTypes: 'Vincular tipos de producto',
			settingsProduct: 'Vinculación de productos (opcional)',
			labelLinkManageServices: 'Crear o eliminar servicios adicionales',
			labelUnderstandServices: 'Entender los servicios',
			labelLinkAccountingCategories: 'Crear una categoría contable',
			labelLinkOutlets: 'Gestiona tus Outlets',
			labelLinkIntegrationPOS: 'Integration del POS a Mews Operation',
			settingsFloorOutlet: 'Vincular de los  salas(opcional)',
			documentation: {
				manageServices:
					'https://help.mews.com/s/article/create-or-delete-an-additional-service?language=es',
				understandServices:
					'https://help.mews.com/s/article/understanding-services?language=es',
				manageAccountingCategories:
					'https://help.mews.com/s/article/create-an-accounting-category?language=es',
				manageOutlets:
					'https://help.mews.com/s/article/set-up-outlets?language=es',
				integrationPOS:
					'https://help.mews.com/s/article/point-of-sale-integrations-for-mews-operations?language=es',
			},
			mewsConfigNotComplete:
				'La configuracion no esta terminado al Mews Operations.',
		},
		misterBooking: {
			cancelNoteTitle: 'Cancelar una entrada',
			cancelNoteDescription:
				"Esta función permite cancelar un billete por su pmsId en MisterBooking. Tenga en cuenta que la facturación de L'Addition no se modificará / rectificará",
			cancelNoteButton: 'Cancelar',
			cancelNoteSuccess:
				'El ticket N°%{noteId} ha sido cancelado en MisterBooking',
			cancelNoteFail:
				'Se ha producido un error al cancelar el billete N°%{noteId} en MisterBooking y/o este identificador no existe',
		},
		tickncook: {
			select_kitchen: 'Seleccione su cocina',
			kitchen: 'Cocina',
		},
	},
	exports: {
		deploy_date: 'Fecha de implementación',
		exportModal: {
			export: 'Exportar',
			to_export: 'Exportar',
			to_deploy: 'Implementar',
			export_name_empty_error: 'Por favor, introduzca el nombre de exportación',
		},
		sectionList: {
			my_sections: 'Secciones',
			my_products: 'Mis productos',
			details: 'Detalles',
			select_section: 'Seleccionar una sección',
			recap: 'Resumen',
			add_section: 'Añadir una sección',
			new_section: 'Sección sin título',
			description_placeholder: 'Descripción ... ',
		},
		recapExport: {
			empty: '(vacío)',
			incompleted_product:
				'Atención, los productos con notificación están incompletos.',
		},
		formSection: {
			section_name: 'Nombre de la sección',
			section_description: 'Descripción (facultatif)',
			section_delete: 'Eliminar la sección',
			section_name_empty_error: 'Por favor, introduzca un nombre de sección',
			empty_product: 'Ningún producto en esta sección',
			confirm_delete_product:
				'¿Está seguro de querer eliminar el producto de esta sección ?',
			confirm_delete_section:
				'¿Está seguro de que quiere eliminar esta sección ?',
		},
		formProduct: {
			product_delete: 'Eliminar este producto',
		},
		menuLevel: {
			checkbox_isHub: 'Pivot',
			hint_isHub:
				'Si se selecciona esta opción, la aplicación enviará tantos menús como productos haya en el nivel de menú seleccionado',
		},
		field_cant_be_empty: 'Este campo no puede estar vacío',
		banner: {
			title: 'Photo cover',
			description:
				'Debe mostrar su marca y animar a los clientes a visitar la página de su restaurante. Formatos aceptados: JPEG, PNG. Al menos 1920x1080 píxeles, formato 16:9.',
			confirm_delete:
				'¿Está seguro de que desea eliminar la photo cover de este socio?',
			add: 'Añadir photo',
		},
	},
	request: {
		fail: 'Error en la solicitud de %{endPoint}',
	},
	breadcrumb: {
		partners: 'Socios',
		home: 'Inicio',
		info: 'Información general',
		eventWebhook: 'Webhooks',
	},
	variable: {
		title_modal: 'Aviso',
		description_modal:
			'Los cambios en su menú han afectado a una o más de sus exportaciones. Por lo tanto, las exportaciones afectadas se han modificado en consecuencia y se han vuelto a desplegar automáticamente.\n',
		got_it: 'Vale',
		impacted_export: 'Exportación(es) afectada(s)',
		generate_new_export: 'Generar una nueva exportación',
		multisession: {
			label:
				'Cuidado, parece que ya hay una sesión abierta en este servicio. ¿Está seguro de querer acceder ? Esto cerrará cualquier otra sesión activa',
			button: 'Activar mi sesión',
		},
	},
	errorBoundary: {
		title: 'Se ha producido un error',
		keepError:
			'Si el problema persiste, póngase en contacto con nuestro soporte técnico.',
		reload: 'Recargar',
		errorCode: 'Código de error',
		interjections: ['Ouch', 'OOOH', 'Dios mío', 'OHOH'],
		configError:
			'Su cuenta no ha sido configurada correctamente, por favor contacte con el soporte técnico.',
	},
	firstLogin: {
		fail: 'Se ha producido un error durante el inicio de su cuenta',
		init: 'Iniciar su cuenta',
		errors: {
			SET_INFO_FAIL:
				'Su cuenta ha sido iniciada pero su información no ha podido ser recuperada, recuerde completarla en la sección "Información".',
		},
	},
	404: {
		not_found: 'Página no encontrada',
		go_home: 'Volver a la página de inicio',
	},
};
