/**
 * Instructions
 *  - Renseigner les traductions par Module
 *  - Lorsque l'on rajoute une traduction, la rajouter également sur les autres fichiers de traductions (meme non traduite)
 */
export default {
	general: {
		save: 'Enregistrer',
		valid: 'Valider',
		confirm: 'Confirmer',
		cancel: 'Annuler',
		yes: 'Oui',
		no: 'Non',
		monday: 'Lun.',
		tuesday: 'Mar.',
		wednesday: 'Mer.',
		thursday: 'Jeu.',
		friday: 'Ven.',
		saturday: 'Sam.',
		sunday: 'Dim.',
		add: 'Ajouter',
		edit: 'Modifier',
		deploy: 'Déployer',
		close: 'Fermer',
		logout_confirm: 'Êtes-vous sûr(e) de vouloir vous déconnecter ?',
		loading: 'Chargement...',
		warning: 'Attention',
		information: 'Information',
		delete: 'Supprimer',
		laddition: "L'Addition",
		i_get_it: "J'ai compris",
		help: 'Aide',
		contact: 'Contact',
		documentation: 'Documentation',
		error: 'Une erreur est survenue',
	},
	dashboard: {
		dashboard: 'Dashboard',
		export: 'Mes exports',
		infos: 'Mes informations',
		event_webhook: 'Evenements / Webhooks',
		partners: 'Mes partenaires',
		partner_export: 'Exports produits (tests)',
		menu: 'Menu digital',
	},
	info: {
		title: 'Informations',
		description: 'Description',
		short_description: 'Description courte',
		about: 'Présentation',
		changements_success: 'Vos changements ont bien été pris en compte',
		remaning_caracters: 'Caractères restants',
		picture: {
			menu: 'Menu digital',
			title: 'Photos',
			subtitle:
				"Vous pouvez ajouter un fichier PDF ou jusqu'à 8 images (max 20mo)",
			new_picture: 'Ajouter une photo',
			drag_drop_image: 'Glisser / Déposer votre image',
			or: 'OU',
			import: 'Importer',
			name: 'Nom de la photo',
			description: 'Description',
			confirm_delete: 'Êtes-vous sûr(e) de vouloir supprimer cette image ?',
			picture_porn:
				'Attention, la photo que vous souhaitez ajouter comporte un contenu inapproprié. Veuillez réessayer',
			nsfw_check: 'Vérification de votre image',
			bad_extension: 'Les fichiers de type %{extension} ne sont pas acceptés',
			too_heavy: 'Le fichier est trop volumineux',
			max_size: 'Taille maximum : ',
		},
		address: {
			address: 'Adresse',
			number: 'N°',
			street: 'Nom de la voie',
			city: 'Ville',
			zip_code: 'Code postal',
			name: 'Enseigne',
			state: 'Région / Etat',
			country: 'Pays',
			custom_information: 'Informations complémentaires',
			longitude: 'Longitude',
			latitude: 'Latitude',
			locate_button: {
				init: 'Localiser automatiquement sur la carte',
				progress: 'Localisation en cours...',
				fail: 'Echec : Aucune correspondance trouvée',
				success: 'Votre établissement a bien été localisé',
			},
			location_missing_fields:
				'Vous devez renseigner l\'adresse complète pour lancer la localisation automatique (Le numéro et "Région/État" est facultatif)',
			location_failed:
				"Votre établissement n'a pas été localisé, merci de vérifier l'adresse. Vous pouvez aussi déplacer le pointeur manuellement.",
			choose_location: 'Veuillez sélectionner une adresse',
		},
		dates: {
			name: 'Nom',
			open_time: 'Plages horaires',
			start_date: 'Date de début',
			end_date: 'Date de fin',
			all_day: 'Toute la journée',
			start: 'Début',
			end: 'Fin',
			days: 'Jours',
			reserv_dates: "Horaires d'ouverture",
			noon: 'Midi',
			evening: 'Soir',
			close_period: 'Période(s) de fermeture',
			add_closing_date: 'Ajouter une date de fermeture',
			error_double_closing_date:
				'Vous avez déjà enregistré cette période de fermeture',
			confirm_delete:
				'Êtes-vous sûr(e) de vouloir supprimer cette période de fermeture ?',
		},
	},
	menuOnline: {
		loadQrcode: 'Télécharger Qrcode',
		goto: 'Visualiser',
	},
	binding: {
		button: 'Association',
		button_label_price: 'prix',
		button_label_payment: 'paiement',
		button_label_table: 'salle',
		title: 'Associations',
		select_payment_type: 'Moyen de paiement ',
		select_floor: 'Salle ',
		select_price_category: 'Catégorie de prix (facultatif) ',
		none: '- Sélectionner -',
		help:
			"Sur l'application et le reporting, toutes les commandes prises via ce partenaire seront automatiquement associées à :",
		empty_payment_type: 'Aucun moyen de paiement',
		empty_floor: 'Aucune salle',
		empty_price_category: 'Aucune catégorie de prix',
	},
	product_quantity: {
		button: 'Seuil disponibilité des produits',
		title: 'Seuil minimum de disponibilité des produits',
		help:
			'Définissez le seuil minimum des produits restants en stock sur la caisse, qui va déclencher l’indisponibilité des produits sur les menus en ligne de TOUS VOS PARTENAIRES.',
		help_2:
			'Les ajustements sont faits automatiquement durant tout le service, en fonction des stocks sur l’iPad.',
		input: 'Seuil',
	},
	upload: {
		fail: 'Une erreur est survenue lors du téléchargement du votre image',
		error_code: {
			LIMIT_FILE_SIZE:
				'Le fichier que vous tentez de charger est trop volumineux',
		},
	},
	webhook: {
		categories: {
			all: 'Tous les évenements',
		},
		no_event: 'Aucun évenement',
		confirm_delete_param: 'Êtes-vous sûr de vouloir supprimer ce paramètre ?',
		delete_confirm:
			'Êtes-vous sûr de vouloir remettre à 0 votre Hook ? cette action est irréversible.',
		name: {
			partnerRequestGetOrderLive: "Récupération d'une commande (sur l'ipad)",
			partnerRequestGetOrder: "Récupération d'une commande (sur l'API)",
			partnerRequestGetStore: 'Récupération des infos restaurant',
			partnerRequestGetOrders: "Récupération des commandes (sur l'API)",
			partnerRequestGetUsers: "Récupération des utilisateurs de l'application",
			partnerRequestGetProducts: 'Récupération des produits',
			partnerRequestGetMenu: 'Récupération des produits de la carte courante',
			partnerRequestGetExtras: 'Récupération des suppléments',
			partnerRequestPostPaymentLive:
				"Réception d'un nouveau paiement sur l'iPad",
			partnerRequestPostMediationLive:
				"Ajout d'une nouvelle donnée sur la médiation",
			partnerRequestPostOrderLive:
				"Reception d'une nouvelle commande sur l'iPad",
			prPostCancelOrderLive: "Annulation d'une commande sur l'ipad",
			apiDataRemovePaymentLine: "Suppression d'un paiement depuis l'ipad",
			apiDataValidationPaymentLine:
				"Validation de l'état d'un paiement depuis l'ipad",
			apiDataOpenShift: 'Ouverture du service',
			apiDataCloseShift: 'Fermeture du service',
			apiDataUpdateOrder: "Mise à jour d'une commande",
			apiDataUpdateOrderStatus: "Mise à jour du statut d'une commande",
			apiDataUpdateQuantity: 'Mise à jour de la quantité',
			stockAvailable: 'Stocks disponibles',
		},
		availableParams: {
			relatedUserExternalId: 'Identifiant du restaurant partenaire',
			partnerTokenOut: 'Token du partenaire',
			customerId: 'Id partenaire',
		},
	},
	partner: {
		title: 'Partenaires',
		active: 'Actif',
		inactive: 'Inactif',
		tooltip_partner_info: 'Identifiant et token du partenaire',
		tooltip_partner_edit: 'Configurer le partenaire',
		tooltip_partner_help: "Besoin d'aide ?",
		token_id_customer_undefined: 'Indisponible',
		infoUndefined: 'Non définie',
		put_active: 'Rendre actif',
		search: 'Rechercher un partenaire...',
		link_espace_client: 'Dashboard',
		soon: 'Mes futurs partenaires ?',
		configuration: 'Configurer',
		configuration_in_progress: 'En configuration',
		continue_configuration: 'Continuer la configuration',
		begin_configuration: 'Commencer la configuration',
		one_click_activate: 'Activer en 1 clic',
		more_info: 'En savoir plus',
		unlock: 'Débloquer',
		call_partner_support: 'Contactez votre partenaire pour en savoir plus',
		config_error: {
			title: 'Une erreur est survenue',
			partner_config_missing: "Ce partenaire n'est pas géré",
			auth_linking: "Problème de liaison partenaire <> l'Addition",
			init_partner: "Problème d'initialisation du partenaire",
		},
		configuration_connector: {
			wait_title: 'Votre demande a bien été prise en compte.',
			wait_label:
				'Après la signature du devis revenez ici pour configurer le connecteur.',
			wait_label_manual_onboarding:
				'Après la signature du devis, nos équipes vont configurer votre connecteur',
			payment_method:
				"Choisissez un mode paiement pour commencer l'activation du connecteur",
			pay_cb: 'Paiement CB : %{price}',
			need_contact: 'Se faire recontacter par nos équipes',
			redirect: "Vous allez être redirigé vers l'interface de paiement",
		},
		label_lead_partner: 'Pas encore de compte %{partner} ?',
		prospect_lead:
			'Voulez-vous vous faire recontacter par le service commercial de %{partner} ?',
		lead_partner_notif_ok:
			'Le service de %{partner} à été notifié. Vous serez contacté dans les plus brefs délais',
		lead_partner_notif_ko:
			'Une erreur est survenue. Veuillez réesayer, sinon, contactez directement %{partner}',
		connector_confirm:
			'Je suis intéressé et souhaite être recontacté pour activer le service !',
		connector_confirm_ok:
			'Le service commercial a été notifié. Vous serez contacté dans les plus brefs délais',
		connector_confirm_ko:
			"Le service commercial n'a pas pus être notifié. Veuillez réessayer ou contactez le directement",
		deliveroo_laddition_id:
			"Votre identifiant d'établissement (à renseigner sur le Hub Deliveroo avant de passer à l'étape suivante)",
		deliveroo_brand_id:
			'Veuillez renseigner identifiant de marque (ou Brand ID) fourni par Deliveroo',
		deliveroo_export: {
			title:
				'Rendez-vous sur votre Hub Deliveroo pour finaliser la connexion :',
			1: '1. Depuis la page intégrations, cliquez sur “Vérifiez le statut du menu”',
			2: '2. Ensuite cliquez sur “Connectez vos commandes”',
			3: '3. La connexion est terminée ! Si votre caisse est connectée à internet les commandes seront synchronisées !',
		},
		rushour_laddition_id:
			"Votre identifiant d'établissement (à renseigner sur la page Rushour avant de passer à l'étape suivante)",
		checkMinCharLength: {
			title:
				'Le nom des éléments suivants de votre menu dépassent la limite de caractères autorisée par votre partenaire (entre %{min} et %{max} caractères) :',
			change_product:
				"Pour changer un produit, modifier le nom dans le formulaire d'export",
			change_supp:
				'Pour changer les suppléments, rendez vous sur la popup de modification de suppléments (Bouton "Modifier les suppléments") ',
			product: 'Produit',
			cat_supp: 'Catégorie de supplément',
			supp: 'Supplément',
		},
		reminder: 'Rappel :',
		enter_key_at_partner: 'Communiquer la clé au partenaire',
		enter_token_at_partner: 'Renseigner la clé sur l’interface du partenaire',
		waiting_for_partner:
			'La clé est bien enregistrée sur l’interface du partenaire ?',
		active_link_ipad: "Activer la liaison sur l'application",
		link_with_partner_missing:
			'La liaison avec le partenaire n’a pas pu être confirmée. Vérifiez que la clé a bien été enregistrée sur l’interface du partenaire ou contactez le support du partenaire.',
		link_with_partner_ok: 'La liaison avec le partenaire est confirmée',
		check_overpaid:
			'Vérifiez que l’option « Transformer les trop-perçus en pourboires » de vôtre application soit bien activée',
		update_nuc: "Vous devez mettre à jour votre serveur l'Addition",
		connexion_with_partner_missing:
			'La connexion avec le partenaire n’a pas pu être confirmée. Vérifiez que vous avez bien activé la connexion avec le partenaire sur l’application L’Addition, et que votre iPad est allumé et l’application ouverte.',
		using_ipad:
			"Il semblerait que vous utilisez un iPad pour terminer la liaison avec l'application L'Addition. Le test de connectivité doit être déclenché depuis un autre appareil (PC ou autre tablette) pour confirmer la liaison. Si vous disposez de plusieurs iPads, il vous est possible de déclencher le test depuis l'un d'entre eux.",
		generate_token: 'Cliquer ici pour générer la clé',
		copy_token: 'Copier la clé',
		token_copied: 'Clé copiée',
		check_link_partner_to_customer: 'Vérifier la liaison',
		check_connexion_with_partner: 'Vérifier la connexion avec le partenaire',
		check_connexion: 'Vérifier la connexion',
		partner_ok: 'La liaison avec le partenaire a été configurée avec succès',
		ladditionMenu_accessAdminMenu_missing:
			"Votre compte n'est pas prêt pour utiliser la carte en ligne, veuillez contacter le support technique",
		validation_partner_checking_error: {
			need_update:
				'Mettez à jour tous vos appareils depuis l’AppStore (dernière version disponible)',
			not_available_origin:
				"Ce partenaire n'est pas disponible pour L'Addition Origine",
			not_available_suite:
				"Ce partenaire n'est pas encore disponible pour L'Addition Suite",
		},
		check_uuid: "Vérifier l'uuid",
		check_existing_acount:
			'Continuez votre configuration de Pay+ en cliquant sur ce lien. Une fois complété, cliquez sur "Suivant"',
		check_existing_acount_link: 'Configuration',
		ubereats: {
			url_signup: 'https://www.ubereats.com/restaurant/fr-FR/signup',
			label_login: 'Connexion à Uber Eats',
			label_signup: 'Pas encore de compte ? Créez votre compte ici',
		},

		missing_uuid: 'Merci de saisir votre uuid Uber Eats',
		set_uuid: 'Renseigner le store uuid Uber Eats',
		documentation: "Guide de configuration de l'Addition pour %{partner}",
		product_id_at_partner:
			'Renseigner les codes articles de L’Addition sur l’interface Uber Eats',
		check_if_product_id:
			'Vérifier que les codes articles L’Addition sont bien renseignés sur chaque article Uber Eats',
		check_if_product_id_ok: 'Vérifier',
		check_product_incomplete:
			'Merci de compléter les codes manquants sur votre interface Uber Eats, et d’appuyer à nouveau sur le bouton “Vérifier” ensuite',
		check_product_empty:
			'Avez-vous bien suivi la procédure et renseigné les codes articles de L’Addition sur l’interface Uber Eats ?',
		wrong_correspondance_id:
			"Certains articles sur Uber Eats ne possèdent pas de code article L'Addition, merci de les renseigner.",
		wrong_uuid:
			'La vérification de l’uuid a échoué, veuillez prendre contact avec votre Account Manager ou le support Uber Eats (deux raisons possibles : uuid incorrect ou liaison inactive côté Uber Eats)',
		uuid_correct: 'Votre uuid Uber Eats a bien été enregistré',
		wrong_option_partner:
			'Une erreur est survenue lors de la récupération des options spécifiques au fonctionnement du partenaire',
		wrong_initialization:
			"Une erreur est survenue lors de l'activation du compte chez le partenaire",
		wrong_desactivation:
			'Une erreur est survenue lors de la désactivation du compte chez le partenaire',
		wrong_activation_desactivation:
			"Une erreur est survenue lors de l'activation/désactivation du partenaire",
		need_export_to_activate:
			"Vous devez créer un export et l'exporter depuis votre page partenaire (roue crantée) pour activer votre liaison",
		ubereats_wrong_initialization:
			"Une erreur est survenue lors de l'autorisation de votre compte Uber. Veuillez rééssayer et si le probleme persiste, contactez le support ",
		ubereats_success_initialization:
			'Connexion à votre compte UberEats OK. Vous pouvez continuer votre onboarding',
		ubereats_wrong_provisioning:
			"Une erreur est survenue lors de la selection de l'établissement",
		ubereats_redirection:
			"Vous allez être redirigé vers l'interface de login Uber Eats",
		external_config_redirection:
			"Vous allez être redirigé vers l'interface du partenaire",
		ubereats_no_store:
			'Nous ne recevons aucun établissement lié à votre compte Ubereats, merci de contacter le support Ubereats pour résoudre ce problème.',
		ubereats_pos_status: {
			title: "Reception des commandes sur l'iPad",
			enabled: 'Activée',
			disabled: 'Désactivée',
			unknow: 'Inconnue',
			label_unknow:
				"Nous n'avons pas réussi à récupérer le status de reception des commandes. Veuillez actualiser la page ou contacter le service integration de L'Addition.",
			label_disabled:
				'Pour activer la réception des commandes sur l’iPad, veuillez configurer et exporter votre carte L’Addition vers UberEats',
		},
		ubereats_order_validation_switch:
			'Validation automatique des commandes en provenance de UberEats',
		ubereats_order_validation_title:
			'Gestion des commandes depuis l’application L’Addition',
		ubereats_order_manager_info:
			"Si l'option est activée, La validation des commandes se fera sur votre tablette L'Addition au lieu de votre tablette UberEats",
		ubereats_order_validation_info:
			'Si l’option est désactivée, vous devrez accepter ou refuser les commandes manuellement sur l’iPad.',
		missing_id_list:
			"Certains articles sur Uber Eats ne possèdent pas de code article L'Addition",
		empty_id_list:
			"Aucun article sur Uber Eats ne possède de code article L'Addition",
		right_correspondance_id:
			"La vérification des codes articles s'est bien déroulée",
		ipad_not_live:
			'L’iPad doit être connecté, L’Addition ouverte, Modules partenaires activés (Réglages -> Intégrations partenaires -> Activation des modules partenaires) sur l’iPad maître',
		must_sync_ipad:
			'Pour terminer la configuration de votre connecteur, veuillez effectuer une synchronisation (Réglages -> Synchro manuelle) et vérifier l\'état du bouton "Activer les modules partenaires" (Réglages -> Intégrations partenaires)',
		download_guide: 'Télécharger le guide',
		integration_guide_pdf: {
			celladdition:
				'https://cdn.laddition.com/27/integration-laddition-clickandcollect.pdf',
			ubereats:
				'https://cdn.laddition.com/27/integration-laddition-ubereats.pdf',
			deliverect:
				'https://cdn.laddition.com/27/integration-laddition-deliverect.pdf',
			ladditionTicket:
				'https://cdn.laddition.com/27/integration-laddition-ladditionticket.pdf',
			pms: 'https://cdn.laddition.com/27/integration-laddition-pms.pdf',
			mewsSystems:
				'https://cdn.laddition.com/27/integration-laddition-mewssystems.pdf',
			misterBooking:
				'https://cdn.laddition.com/27/integration-laddition-misterbooking.pdf',
			tabesto: 'https://cdn.laddition.com/27/integration-laddition-tabesto.pdf',
			dood: 'https://cdn.laddition.com/27/integration-laddition-dood.pdf',
			deliveroo:
				'https://laddition.slite.page/p/UfXGWcFa_cHahE/en-cours-Guide-d-integration-L-Addition-and-Deliveroo',
			ladditionTapToPay:
				'https://cdn.laddition.com/27/integration-tap-to-pay.pdf',
		},
		tap_to_pay: {
			please_agree_cgv:
				"Pour continuer, merci d'accepter les Conditions Générales de Vente",
			display_cgv: 'Afficher les CGV',
			cgv_allready_sign:
				'Vous avez déja signé les Conditions Générales de Vente',
			continue_cgv: 'Continuer',
			cgv_pdf:
				'https://www.laddition.com/sites/default/files/cgv_laddition_pay.pdf',
			cgv_popup_title: 'Conditions générales de vente',
			cgv_checkbox: "J'accepte les CGU",
			need_onbaording:
				'Veuillez remplir le formulaire de création de compte Pay+ que vous avez reçu par email.',
			in_validation:
				"Votre compte est en cours de validation. la validation peut prendre jusqu'a 48h (en jours ouvrés) le support Pay+ vous recontactera si la vérification a échoué.",
			init_account_label:
				"Votre compte Pay+ n'est pas encore configuré. Renseignez votre email pour recevoir le formulaire de création de compte :",
			email_sended:
				"Un email viens de vous être envoyé sur : %{email}. Suivez les instructions pour configurer votre compte l'Addition Pay+. Une fois votre compte configuré et vérifié par nos équipes, revenez ici pour compléter votre intégration ",
			success: 'Bravo, vous êtes prêts à utiliser Tap to Pay sur iPhone',
			success_popup:
				'Nous avons bien reçu votre demande pour Tap to Pay sur iPhone ! Vous êtes actuellement en file d’attente afin d’accéder au service, nous vous préviendrons dès que vous pourrez procéder à vos premiers encaissements avec Tap to Pay sur iPhone.',
			download_app_1:
				"Pour télécharger l'application l'Addition Tap-to-Pay vous pouvez scanner directement ce QR Code depuis votre iPhone",
			download_app_2:
				"Vous pouvez également vous rendre sur l'App Store et chercher 'L'Addition pay+'",
			documentation: 'Guide de configuration pour Tap to Pay sur iPhone',
			page_download_app:
				"En attendant, vous pouvez télécharger l'application L'Addition Pay+. Vous pouvez scanner directement ce QR Code depuis votre iPhone. Vous pouvez également vous rendre sur l'App Store et chercher \"L'Addition pay+\"",
			input_placeholder: 'Votre email',
			data_empty:
				"Impossible de récupérer le document a signer. Si le problème persiste, contactez le support L'Addition",
			refresh_data: 'Reessayer',
			sign_error:
				'Une erreur est survenue lors de la validation de la signature.',
			sign_success: 'Document signé. Vous pouvez continuer votre configuration',
		},
		confirm_didnt_read_doc_yolo: {
			export:
				"Attention, cette documentation vous guidera jusqu'à l'export final de vôtre carte, êtes vous sur de vouloir continuer sans avoir télechargé la documentation?",
			tap_to_pay:
				'Attention, cette documentation vous guidera jusqu’à la configuration complète de Tap to Pay sur iPhone, êtes vous sur de vouloir continuer sans l’avoir téléchargée ?',
		},

		select_store: 'Selectionez votre restaurant',
		partial_title: 'Voici la liste des codes manquants sur Uber Eats :',
		choose_room:
			"Choisir une salle dans laquelle les commandes provenant du partenaire seront enregistrées sur l'Addition",
		choose_room_cmd_en_ligne:
			'Choisir une salle dans laquelle les commandes en ligne seront enregistrées sur L’Addition',
		choose_paiement_ticket: 'Sélectionner un moyen de paiement',
		choose_paiement:
			'Choisir un type de paiement avec lequel les commandes provenant du partenaire seront enregistrées sur L’Addition',
		choose_paiement_cmd_en_ligne:
			'Choisir un type de paiement avec lequel les commandes en ligne seront enregistrées sur L’Addition',
		choose_priceCategory:
			'Choisir une catégorie de prix avec laquelle les commandes provenant du partenaire seront enregistrées sur L’Addition (FACULTATIF)',
		choose_priceCategory_cmd_en_ligne:
			'Choisir une catégorie de prix avec laquelle les commandes en ligne seront enregistrées sur L’Addition (FACULTATIF)',
		paiement_empty: 'Veuillez spécifier un moyen de paiement',
		room_empty: 'Veuillez spécifier une salle',
		actives_product: 'Produits disponibles',
		suspends_product: 'Produits non disponibles',
		description_menu_selector:
			"Veuillez s'il vous plaît, sélectionner une carte:",
		nb_product: 'Nombre de produit :',
		type_carte: 'Type de carte :',
		primary: 'Permanente',
		secondary: 'Secondaire',
		begin: 'Commencer',
		config: 'Configurer',
		init_liaison: 'Initialiser le paramétrage',
		init_liaison_cel: 'Initialiser le paramétrage de la commande en ligne',
		configuration_guide: 'Continuer la configuration de la commande en ligne',
		must_do_export:
			"Partenaire activé. Pour activer la reception des commandes sur l'iPad, vous devez générer un export",
		como_input_pos_external_id:
			"Veuillez renseigner l'identifiant Como <> L'addition",
		como_input_joining_item:
			"Veuillez renseigner l'identifiant de création d'utilisateur (Joining Item)",
		como_input_api_key: 'Veuillez renseigner la clé API de Como',
		como_label_link_auth: "Liaison entre l'Addition et Como",
		deliverect_input_location_id:
			'Veuillez renseigner le location ID  (fourni par Deliverect)',
		deliverect_input_account_id:
			"Veuillez renseigner l'AccountID (fourni par Deliverect)",
		deliverect_check_link: 'Vérification',
		unlink_order_partners:
			"Vous avez actuellement une configuration directe avec Deliveroo et/ou UberEats. Finaliser l'intégration désactivera ces derniers. Voulez vous continuer ? ",
		input_set_customer_id:
			"Veuillez renseigner l'identifiant/clé fourni(e) par le partenaire",
		step: {
			step: 'Etape',
			token_copy: 'Copier votre token',
			next_step: 'Étape suivante',
			finish: 'Terminer',
		},
		api_key_verified: 'Clé API vérifiée',
		deliverect_credientials_verified: 'Account/Location ID vérifiés',
		misterBooking_credientials_verified: 'Hotel ID vérifié',
		eviivo_credentials_verified: 'PropertyShortName vérifié',
		dood_customer_id:
			'Veuillez rentrer votre identifiant client (fourni par Dood)',
		tabesto_credientials_verified: 'Clé de configuration Tabesto vérifié',
		mews_credientials_verified: 'Access token vérifié',
		zerosix_credientials_verified: 'Token vérifié',
		dishop_credientials_verified: 'Identifiant client vérifié',
		obypay_credientials_verified: 'identifiant Obypay vérifié',
		credientials_verified: 'Identifiant vérifié',
		token_tabesto: 'Veuillez renseigner la clé de configuration Tabesto',
		token_koust: 'Veuillez renseigner le token Koust',
		token_barratio: 'Veuillez renseigner le token Barratio',
		misterbooking_customer_id:
			'Veuillez renseigner votre Hotel ID (fourni par MisterBooking)',
		eviivo_customer_id:
			'Veuillez renseigner votre PropertyShortName (fourni par Eviivo)',
		mews_customer_id:
			'Veuillez renseigner votre Access Token (fourni par Mews)',
		tickncook_customer_id:
			"Veuillez renseigner l' Account ID (fourni par TickNCook)",
		zerosix_customer_id: 'Veuillez renseigner votre Token (fourni par Zerosix)',
		dishop_customer_id:
			"Veuillez renseigner l'identifiant client fourni par Dishop",
		generated_customer_id: 'Identifiant partenaire généré',
		put_customer_id_at_partner:
			"Veuillez le renseigner dans l'interface du partenaire pour terminer l'intégration",
		onboarding_common_errors: {
			general:
				'Une erreur est survenue pendant la connexion avec le partenaire. Veuillez réessayer. Contactez le support technique si le problème persiste.',
			set_auth_link:
				'Une erreur est survenue pendant la connexion avec le partenaire',
			check_customer_id: "L'identifiant/clé partenaire n'a pas été reconnu.",
			token_generation:
				'Une erreur est survenue pendant la génération du token de connexion, vérifiez votre token utilisateur.',
			set_entities:
				"Une erreur est survenue lors de la configuration du moyen de paiement/Catégorie de prix/Salle sur l'iPad",
			set_stock_online:
				'Une erreur est survenue lors de la configuration du compte',
			input_validator:
				"Le format du texte n'est pas celui attendu. Etes vous sûr d'avoir correctement renseigné champ demandé ?",
		},
		onboarding_como_errors: {
			set_branch_pos_id:
				'Une erreur est survenue lors de la génération de votre identifiant de fidélité',
			set_api_key:
				'Une erreur est survenue lors de la vérification de la connexion API, vérifiez la clé',
			set_liaison_como: 'Une erreur est survenue lors de la liaison avec Como',
			set_liaison_webview_loyalty:
				"Une erreur est survenue lors de la liaison avec le connecteur de fidélité l'Addition",
			set_api_discount:
				"Une erreur est survenue lors de la configuration des remises sur l'iPad",
			set_api_payment_type:
				"Une erreur est survenue lors de la configuration du moyen de paiement sur l'iPad",
		},
		onboarding_deliveroo_errors: {
			set_brand_id:
				"Une erreur est survenue, avez vous correctement renseigné l'identifiant L'Addition sur votre plateforme Deliveroo ?",
		},
		onboarding_deliverect_errors: {
			set_location_id:
				"Une erreur est survenue lors de l'enregistrement du location id ",
			set_account_id:
				"Une erreur est survenue lors de l'enregistrement du account id ",
			check_acount_location:
				'Une erreur est survenue lors de la vérification de vos account/location id, veuillez les re-renseigner.',
			set_variable_config_channel:
				'Une erreur est survenue, veuillez reesayer. Si le problème persiste, contactez le support',
			set_liaison_deliverect:
				'Une erreur est survenue lors de la liaison avec Deliverect',
			mandatory_field: 'Ce champ est obligatoire',
			set_table_and_paiement:
				"Une erreur est survenue lors de l'assignation de votre moyen de paiement et salle par défaut",
			same_account_location_id:
				'Les location_id et account_id ne peuvent pas être identiques. Merci de les re-renseigner',
		},

		onboarding_ladditionticket_errors: {
			set_customer_id:
				"Une erreur est survenu lors de l'enregistrement de l'identifiant client",
			call_laddition_ticket:
				'Une erreur est survenue pendant la connexion avec le partenaire',
			set_api_variables:
				"Une erreur est survenu lors de l'initialisation des variables de fonctionement",
		},
		onboarding_tabesto_errors: {
			set_table_payment_and_price_category:
				"Une erreur est survenue lors de l'assignation du moyen de paiement / salle / catégorie de prix par défaut",
			set_token_tabesto: "La clé de configuration Tabesto n'a pas été reconnue",
		},
		onboarding_misterBooking_errors: {
			check_hotel_id:
				'Une erreur est survenue lors de la vérification de votre Hotel ID. Veuillez le re-renseigner.',
		},
		onboarding_mewsSystems_errors: {
			check_customer_id:
				'Une erreur est survenue lors de la vérification de votre Access Token. Veuillez le re-renseigner.',
			no_selection: 'Vous devez selectionez un élement dans la liste',
		},
		onboarding_eviivo_errors: {
			check_customer_id:
				'Une erreur est survenue lors de la vérification de votre PropertyShortName veuillez le re-renseigner.',
		},
		onboarding_zerosix_errors: {
			check_token: 'Token Zérosix non reconnu.',
		},
		onboarding_dishop_errors: {
			check_token: 'Identifiant client Dishop non reconnu.',
		},
		onboarding_obypay_errors: {
			check_token: 'Identifiant client Obypay non reconnu.',
		},
		onboarding_rushour_errors: {
			set_restaurant_id:
				'Erreur : votre configuration Rushour ne semble pas complète',
		},
		mandatory_field: 'Ce champ est obligatoire',
		link_partner_customer_allready_exist:
			'Une liaison avec ce partenaire et cet ID existe déja. Contactez le support de votre partenaire.',
		link_partner_check_error: 'Une erreur est survenue, merci de réessayer',
		onboarding_success_config: {
			default:
				'A tout moment vous pouvez paramétrer vos options depuis la page de configuration du connecteur %{partner_name}',
			title: 'Connecteur activé',
			pms:
				"Pour terminer l'intégration, veuillez faire correspondre les éléments de votre caisse avec ceux créés sur %{partner_name}",
			export:
				"Pour terminer l'intégration, veuillez configurer votre menu afin de pouvoir l'exporter vers %{partner_name}",
		},
		onboarding_success: 'Connexion activée',
		popupExternalId: 'Identifiant à transmettre au partenaire',
		authenticationToken: "Clé d'authification",
		popupInfo: {
			external_id: 'Identifiant partenaire',
			token: "Clé d'authentification",
			account_id: 'Account id',
			location_id: 'Location id',
			token_barratio: 'Token Barratio',
			koust_token: 'Token Koust',
			kitchen_id: 'Kitchen id',
		},

		update_nuc_ignored:
			"License mono detectée - Etape 2 : mise à jour du serveur l'Addition ignorée.",
		prefix: {
			guest: 'Guest Online',
			netresto: 'Mycawan',
			fourch: 'La Fourchette',
			zen: 'Zenchef',
			bts: 'BtsVendee',
			shine: 'Shine',
			ciss: 'Ciss',
			pmt: 'Pay My Table',
			beefhouse: 'Beef House',
			mediation: 'Mediation',
			bfast: 'Bfast',
			celladdition: 'Click & Collect',
			tgpl: 'The Good Place',
			gstock: 'GStock',
			deliveroo: 'Deliveroo',
			easilys: 'Easilys',
			ubereats: 'Uber Eats',
			snapshift: 'Combo',
			deliverect: 'Deliverect',
			ladditionTicket: 'Ticket / Paiement à table',
			dood: 'DOOD',
			tabesto: 'Tabesto',
			barratio: 'Barratio',
			koust: 'Koust',
			mewsSystem: 'Mews System',
			misterBooking: 'Mister Booking',
			bardistrib: 'Bar Distribution',
			eviivo: 'Eviivo',
			yokitup: 'Yokitup',
			ladditionTapToPay: 'Tap to Pay sur iPhone',
			deepsight: 'Inpulse',
			chaponfin2: "Somm'IT",
			theometrics: 'Food Report',
			1055: 'Dionysols',
			chift: 'Chift',
			stansoft: 'GoStan',
			kolia: 'Kolia',
		},
		option: {
			title: "Configuration de l'iPad",
			event: 'Événement',
			empty: 'Aucune option dans la liste',
			advanced_settings: 'Gestion avancée',
			simple_settings: 'Gestion simple',
			need_update: 'Mise à jour App nécéssaire',
			option_order_paid: 'Commande payée',
			option_order_unpaid: 'Commande non payée',
			category: {
				ticket: 'Ticket de caisse',
				manufacturing: 'Bon de fabrication',
				notification: 'Notification',
				order: 'Commande',
				price: 'Prix',
				other: 'Autre',
			},
			name: {
				autoOrderClosed: 'Commande fermée à la réception',
				autoPrintManufacturing: 'Impression automatique du bon de fabrication',
				autoPrintManufacturingPaid: 'Commande payée',
				autoPrintManufacturingUnPaid: 'Commande non payée',
				table_autoPrintManufacturing:
					'(Table) Impression automatique du bon de fabrication',
				table_autoPrintManufacturingPaid: 'Commande payée',
				table_autoPrintManufacturingUnPaid: 'Commande non payée',
				autoPrintReceipt: 'Impression automatique du ticket',
				autoPrintReceiptPaid: 'Commande payée',
				autoPrintReceiptUnPaid: 'Commande non payée',
				table_autoPrintReceipt: '(Table) Impression automatique du ticket',
				table_autoPrintReceiptPaid: 'Commande payée',
				table_autoPrintReceiptUnPaid: 'Commande non payée',
				checkStock: 'Vérification des stocks avant commande',
				showNotification: 'Notification visuelle à la réception',
				showNotificationPaid: 'Commande payée',
				showNotificationUnPaid: 'Commande non payée',
				table_showNotification: '(Table) Notification visuelle à la réception',
				table_showNotificationPaid: 'Commande payée',
				table_showNotificationUnPaid: 'Commande non payée',
				playNotificationSound: 'Notification sonore à la réception',
				playNotificationSoundPaid: 'Commande payée',
				playNotificationSoundUnPaid: 'Commande non payée',
				table_playNotificationSound:
					'(Table) Notification sonore à la réception',
				table_playNotificationSoundPaid: 'Commande payée',
				table_playNotificationSoundUnPaid: 'Commande non payée',
				printTicketComment: 'Impression du commentaire client sur ticket',
				realPrice:
					'Intégration du chiffre d’affaires (prix réels des produits)',
				manualCancel: 'Annulation manuelle des commandes',
				showPaymentStatus: "Afficher l'icone du status du moyen de paiement",
				autoOrderClosedPaymentLine:
					'Fermeture automatique de la commande si soldée',
				autoPrintReceiptPaymentLine:
					'Impression automatique du ticket si commande soldée',
				showNotificationPaymentLine: 'Notification si commande soldée',
				showNotificationPaymentLine_swift: 'Notification à la reception',
				table_autoOrderFusion: 'Transférer sur les tables ouvertes',
				table_autoOrderFusionPaid:
					'Transférer les commandes payées sur les tables ouvertes ',
				table_autoOrderFusionUnPaid:
					'Transférer les commandes non payées sur les tables ouvertes',
				table_autoForceDirect: 'Ajouter tous les produits en direct',
				checkExactTableName:
					'Inspecter le nom exact de la table pour trouver son identifiant',
				table_checkExactTableName:
					'Inspecter le nom exact de la table pour trouver son identifiant',
				autoPrintPicking: 'Impression du bon d’assemblage',
				autoPrintPickingPaid:
					'Impression du bon d’assemblage (commandes payées)',
				autoPrintPickingUnPaid:
					'Impression du bon d’assemblage (commandes non payées)',
				replaceProductNames: 'Utiliser les noms de produits de la caisse',
				preventPaymentModification: 'Empêcher la modification du paiement',
			},
		},
		configuration_module: {
			title_cc: 'Configuration du module',
			title: "Configuration de l'intégration",
			guide: 'Guide (PDF)',
			link_admin_cc: "Vers l'interface de configuration",
		},
		parameter: 'Paramètres',
		param_description:
			"Les paramètres sont des variables disponibles que vous pouvez intégrer dans l'url, les headers ou le body.",
		variable: 'Variable',
		variable_description: 'Description',
		url_tester_description:
			'Veuillez tester votre URL, vous recevrez un code sous le tag "x-laddition-token-webhook". Veuillez recopier ce code.',
		to_test: 'Tester',
		token: 'token',
		invalid_url: "L'addresse URL n'a pas été validé. Veuillez la vérifier",
		invalid_token: "Le token n'est pas valide, veuillez réessayer",
		valid_token: 'Le token a été validé',
		error_test_request:
			"Une erreur est survenue lors de la requête, veuillez vérifier l'URL et recommencer",
		success_test_request:
			'Votre test a bien été envoyé, veuillez récupérer le token et le spécifier dans le champs nécessaire',
		button_delete: 'Réitinialiser',
		headers: 'Headers',
		params: 'Body',
		method: 'Méthode',
		webhook: 'Webhook',
		url: 'Url',
		key: 'Clé',
		value: 'Valeur',
		error_param: 'Le paramètre "%{param}" n\'existe pas',
		error_param_url: "Le.s paramètre.s que vous avez renseigné n'existe.nt pas",
		cateogies_active: 'Catégories activées',
		categorie_inactive: 'Catégories désactivées',
		products_selector: {
			title: 'Importer des produits',
			select_menu: 'Selectionner un menu',
			import_products: 'Importer des produits',
			no_selection: 'Aucun élement selectioné',
			select_all: 'Tout sélectionner',
			deselect_all: 'Tout désélectionner',
			section_list: {
				title: 'Catégories',
				none: 'Aucune autre section',
				all: 'Tous les produits',
				no_category: 'Sans catégorie',
				menus: 'Menus',
			},
			selected_list: {
				title: 'Sélection',
				no_selection: 'Aucun produit sélectionné',
				x_items_selected: ' + %{number} élément(s) sélectionné(s)',
				unknow_menu: 'Menu',
			},
			selectionable_list: {
				search: 'Rechercher un produit',
				title: 'Produits',
				no_selection: 'Aucun produit dans cette section',
				no_product: 'Aucun produit',
			},
			menu_select_reset_select:
				'Êtes-vous sûr(e) de vouloir changer de carte ? Votre sélection actuelle sera réinitialisée',
		},
		productForm: {
			productSelected: 'Produit sélectionné',
			vat: 'TVA',
			vat_undefined: 'Non définie',
			confirm_delete_image:
				'Êtes-vous sûr(e) de vouloir supprimer cette image ?',
			name: 'Nom',
			visible: 'Visible',
			notVisible: 'Non-visible',
			description: 'Description',
			price: 'Prix',
			active: 'Activé',
			import: 'Importer',
			image: 'Ajouter une image',
			supplement: 'Gérer les suppléments',
			supplement_button:
				'Gérer les suppléments et informations complémentaires',
			empty: "Vous n'avez pas de supplément configuré",
			back: 'Retour',
			reset_button: 'Réinitialiser',
			reset_button_menu: 'Réinitialiser le menu',
			category_supplement_disabled:
				'Rendez-vous dans "Modifier les suppléments" pour modifier le nom, prix et type des suppléments. Les catégories et/ou suppléments désactivés apparaissent grisés.',
			take_away: 'À emporter',
			locally: 'Sur place',
			tooltip_visible:
				'Visibilité - le produit sera toujours visible à la carte mais sera désactivé',
			tooltip_visible_disabled:
				"La visibilité du produit se gère directement depuis l'iPad",
			tooltip_product_in_menu_category_supplement: 'Gestion des suppléments',
			alert_supp_missing:
				"Attention, il semblerais que vous ayez des catégories de suppléments avec aucun suppléments a l'intérieur. Veuillez supprimer cette catégorie de suppléments ou y associer des suppléments",
			image_deliveroo:
				'Formats acceptés : JPEG, PNG. Au moins 1920x1080 pixels, format 16:9.',
		},
		supplements: {
			manage: 'Modifier les suppléments',
			name: 'Nom',
			type: 'Type',
			price: 'Prix',
			image: 'Image',
			supplements: 'supplements',
			reset: 'Remettre à 0',
			reset_confirm:
				'Êtes-vous sûr(e) de vouloir remettre les valeurs par défaut de cette catégorie de suppléments ? Les noms, types et prix des suppléments reviendront à leur valeur initiale.',
			types: {
				1: 'Un seul choix',
				2: 'Plusieurs choix',
				3: 'Plusieurs choix en quantité',
			},
			remove_supplement_extra_confirm:
				'Cela va remettre les catégories supplément par défault pour ce produit. Êtes-vous sûr(e) de vouloir executer cette action ?',
			from: 'De',
			to: 'à',
			maximum_choices: 'choix maximum',
			required: 'Obligatoire',
			empty: 'Aucune catégorie de suppléments pour cette carte',
			empty_menu: "Aucune catégories de supplément n'est configurée",
			no_select_menu: '- Sélectionner -',
			description_1:
				'Vous pouvez modifier les suppléments de votre carte pour les adapter à la commande en ligne (nom, type, prix). Sélectionnez une carte pour commencer.',
			alert_extra_prefix:
				"Les modifications apportées pour ce partenaire n'impactent pas les autres partenaires",
		},
		export: {
			confirm_delete: 'Êtes-vous sûr(e) de vouloir supprimer cet export ?',
			confirm_export: 'Êtes-vous sûr(e) de vouloir continuer ?',
			active: 'Actif',
			see_more: 'Choisir un autre export',
			list: 'Mes exports',
			unamed: 'Export sans titre',
			loading: 'Chargement des données produits...',
			generate_success: 'Export généré avec succès',
			export_fail_empty: 'Vous ne pouvez pas exporter une carte vide',
			generate_fail: 'Une erreur est survenue',
			generate_fail_generation:
				'Une erreur est survenue lors de la génération de votre export',
			generate_fail_notif:
				"Votre export à bien été généré mais votre partenaire n'a pas pu être notifié",
			generate_fail_notif_partner_error: {
				too_many_requests: 'Limitation de 1 export/min par le partenaire',
			},
			incompleted_product:
				'Certains produits ne sont pas complets, ils ne peuvent donc pas être intégrés :',
			new_export: 'Nouvel export',
			new_export_from_another_export: 'Nouvel export à partir de..',
			copy_of: 'Copie de ',
			select_export: '-- Selectionner un export --',
			select_export_empty:
				'Veuillez sélectionner un export dans la liste déroulante',
			export_error: 'Une erreur est survenue',
			no_modification:
				"Il n'y a pas eu de modifications depuis le dernier export, êtes-vous sûr de vouloir réexporter ?",
			menu_configuration: 'Configuration de la carte',
			activate_link_ubereats:
				'Souhaitez-vous activer la réception des commandes Uber Eats sur L’Addition ?',
			copy_cat_supp_extra:
				'Voulez vous également copier les modifications sur les catégories de suppléments et suppléments apportées à %{source} sur %{target}',
			date_last_deployment: 'Date du dernier déploiement',
			date_last_deployment_to: 'à',
			helper_no_export:
				'Pour que vos clients puissent passer des commandes via ce partenaire, vous devez préparer votre export de carte. Cliquez sur "Nouvel export" pour commencer. ',
			helper_not_exported:
				'Vous pouvez a tout moment modifier les sections et produits de votre export. N\'oubliez pas de cliquer sur le bouton "Déployer" pour envoyer votre export de carte au partenaire.',
			deliveroo_export_info:
				"Le déploiement de votre carte peut désactiver les offres paramétrées sur votre hub Deliveroo. Pensez à vérifier vos offres sur votre hub Deliveroo et les réactiver depuis l'onglet Marketer après avoir déployé votre carte.",
			deliveroo_info_1_min:
				"Notez que l'API Deliveroo est limitée à 1 export/minute.",
		},
		general_options: {
			button: 'Options générales',
			title: 'Réglages partenaire généraux',
			ipad_changes: {
				title: "Mise à jour des produits depuis l'iPad",
				description:
					"Vous pouvez configurer les regles de mise à jour de vos export lorsque des produits, catégories de suppléments et suppléments sont synchronisés depuis l'iPad. Si ces options sont cochés. Chaque modification remontée mettra à jour votre export chez vos partenaires de commandes en ligne.",
				label_product_name: 'Mise à jour nom',
				label_product_price: 'Mise à jour prix',
			},
		},
		brandChannel: {
			error_empty:
				'Configuration impossible, aucun channel trouvé. Merci de recharger la page',
			title: 'Configuration des plateformes et marques',
			title_brand: 'Marques',
			title_channel: 'Channels',
			select_placeholder: 'Selection ...',
			brand_channels: 'Cannaux spécifiques',
			add_channel: 'Ajouter canal spécifique',
			channel_name: 'Nom',
			channel_floor: 'Salle',
			channel_payment_type: 'Type de paiement',
			channel_price_category: 'Catégorie de prix',
			delete_confirmation: 'Êtes-vous sur de vouloir supprimer cet élément ?',
			mandatory_field: 'Ce champ est obligatoire',
			brand_tooltip:
				"Un marque ou Dark kitchen correspond à l'association salle / paiement / catégorie de prix nécessaire à l'injection des ventes sur le système de caisse pour un channel donné (pour différencier les articles proposés en magasin et ceux seulement disponibles en ligne)",
			channel_tooltip:
				"Un channel ou une plateforme correspond à l'association salle / paiement / catégorie de prix nécessaire à l'injection des ventes sur le système de caisse (pour différencier UberEats, Deliveroo, etc.)",
			no_channel_set: 'Aucun Channel configuré',
		},
		ladditionTicket: {
			activate_payplus: "Activer la liaison L'Addition Pay+",
			activate_payplus_popup: 'Voulez-vous activer les paiement à table ?',
			payplus_activated: "Liaison L'Addition Pay+ activée",
			payplus_activation_fail:
				"Une erreur est survenue lors de l'activation de la liaison Pay+",
			payplus_activation_fail_no_account:
				"Vous n'avez pas de compte Pay+. Veuillez contacter le support",
			print_title: 'Équipez vos tables de présentoir !',
			print_p1:
				'Composés de deux éléments dissociables, un socle en bois et une plaque de Forex.',
			print_p2:
				'Ce présentoir permettra de scanner le QR code associé à la table et régler en partie ou en totalité la note.',
			print_p3: 'Dimensions : 22x15x5',
			print_btn_order: 'Je passe commande',
			print_btn_config: 'Je configure mes QR Codes',
			tips: {
				save_success: 'Les Pourboires ont bien été enresitrés',
				save_fail:
					"Une erreur est survenue lors de l'enregistrement des pourboires",
				title: 'Gestion des pourboires',
				switch: 'Activer les pourboires',
				empty: 'Aucun pourboire configuré',
				add: '+ Ajouter un tip (maximum 3)',
			},
			optionPrintQR: {
				label: 'Imprimer le QR Code sur le ticket',
			},
		},
		misterBooking_paymentType: {
			CB: 'Carte bleue',
			ESP: 'Espèce',
			CHQ: 'Cheque',
			TICKET_RESTAURANT: 'Ticket restaurant',
			AMEX: 'Amex',
			VIR: 'Virement',
			PERTE: 'Perte',
			CHQVAC: 'Cheque Vacances',
			BON_CADEAU: 'Bon cadeau',
			CHL: 'Chèque logis',
		},
		externalData: {
			load_error:
				'Les données partenaires sont vides ou n’ont pas pu se charger. Veuillez reessayer',
			info:
				"Pour que la configuration soit optimale, vous devez rellier %{entity} L'Addition à équivalent chez votre partenaire. De cette façon, les commandes passées seront correctement renseignées chez votre partenaire",
			info_entity: {
				paymentType: 'tous les moyens de paiements',
				floor: 'toutes les salles',
				floorAll: 'toutes les salles',
				priceCategory: 'toutes les catégories de prix',
				productType: 'tous les types de produits',
				productId: 'les produits que vous souhaitez',
			},
		},
		mewsSystems: {
			serviceInputDescription:
				"Renseignez le service Mews qui sera lié au système L'Addition",
			serviceInputLabel: 'Service',
			outletInputDescription:
				"Renseignez l'outlet Mews qui sera lié au système L'Addition",
			outletInputLabel: 'Outlet',
			paymentInputDescription:
				"Renseignez le moyen de paiement Mews qui sera lié par défaut au système L'Addition",
			paymentInputLabel: 'Moyen de paiement par défaut',
			outletEmpty: 'Liste des outlets Mews vide',
			servicesEmpty: 'Liste des services additionels Mews vide',
			paymentEmpty: 'Liste des catégories comptables/paiement Mews vide',
			refreshData: 'Rafraichir les données Mews',
			documentationDescription:
				'Il semblerait que certaines de vos données Mews systems soient vides. Sont-elles bien renseignées ? Si c\'est le cas, cliquez sur le bouton "Rafraîchir les données Mews". Sinon, merci de consulter la documentation de Mews systems ci-dessous',
			documentationCreateService: 'Créer un service',
			documentationService: 'Comprendre les services autour du POS',
			documentationAccounting: 'Accounting',
			settingsDefault:
				'Configuration outlet / service / et paiement par défaut',
			settingsPayments: 'Liaison moyens de paiements',
			settingsProductTypes: 'Liaison types de produits',
			settingsProduct: 'Liaison produits (facultatif)',
			labelLinkManageServices: 'Créer ou supprimer des services additionels',
			labelUnderstandServices: 'Comprendre les services',
			labelLinkAccountingCategories: 'Créer une catégorie comptable',
			labelLinkOutlets: 'Gérer vos outlets',
			labelLinkIntegrationPOS:
				'Intégration points de vente dans Mews Operation',
			settingsFloorOutlet: 'Liaison des salles (Facultatif)',
			documentation: {
				manageServices:
					'https://help.mews.com/s/article/create-or-delete-an-additional-service?language=fr',
				understandServices:
					'https://help.mews.com/s/article/understanding-services?language=fr',
				manageAccountingCategories:
					'https://help.mews.com/s/article/create-an-accounting-category?language=fr',
				manageOutlets:
					'https://help.mews.com/s/article/set-up-outlets?language=fr',
				integrationPOS:
					'https://help.mews.com/s/article/point-of-sale-integrations-for-mews-operations?language=fr',
			},
			mewsConfigNotComplete:
				"La configuration n'est pas terminée sur votre espace Mews Operations.",
		},
		misterBooking: {
			cancelNoteTitle: 'Annuler un ticket',
			cancelNoteDescription:
				"Cette fonctionnalité permet d'annuler un ticket par son pmsId sur MisterBooking. Attention, le chiffre d'affaires sur L'Addition ne sera pas modifié / rectifié.",
			cancelNoteButton: 'Annuler',
			cancelNoteSuccess:
				'Le ticket N°%{pmsId} à bien été annulée sur MisterBooking',
			cancelNoteFail:
				"Une erreur est survenue lors de l'annulation du ticket N°%{pmsId} sur MisterBooking et/ou cet identifiant n'existe pas",
		},
		tickncook: {
			select_kitchen: 'Veuillez sélectionner votre cuisine',
			kitchen: 'Cuisine',
		},
	},
	exports: {
		deploy_date: 'Date de déploiement',
		exportModal: {
			export: 'Export',
			to_export: 'Exporter',
			to_deploy: 'Déployer',
			export_name_empty_error: "Veuillez renseigner un nom d'export",
		},
		sectionList: {
			my_sections: 'Sections',
			my_products: 'Mes produits',
			details: 'Détails',
			select_section: 'Sélectionnez une section',
			recap: 'Récapitulatif',
			add_section: 'Créer une section',
			new_section: 'Section sans titre',
			description_placeholder: 'Description ... ',
		},
		recapExport: {
			empty: '(vide)',
			incompleted_product:
				'Attention, les produits dotés de la pastille sont incomplets',
		},
		formSection: {
			section_name: 'Nom de la section',
			section_description: 'Description (facultatif)',
			section_delete: 'Supprimer la section',
			section_name_empty_error: 'Veuillez renseigner un nom de section',
			empty_product: 'Aucun produit dans cette section',
			confirm_delete_product:
				'Êtes-vous sûr(e) de vouloir enlever le produit de cette section ?',
			confirm_delete_section:
				'Êtes-vous sûr de vouloir supprimer cette section ?',
		},
		formProduct: {
			product_delete: 'Supprimer ce produit',
			reset_extra:
				'Cela va remettre le produit par défault. Êtes-vous sûr(e) de vouloir executer cette action ?',
		},
		menuLevel: {
			checkbox_isHub: 'Pivot',
			hint_isHub:
				'Si cette option est cochée, l’application enverra autant de menus que de produits à l’intérieur du niveau de menu coché',
			reset_extra:
				'Cela va remettre le menu par default. Êtes-vous sûr(e) de vouloir executer cette action ?',
		},
		field_cant_be_empty: 'Ce champ ne peut pas être vide',
		banner: {
			title: 'Photo cover',
			description:
				'Elle doit mettre en valeur votre marque et inciter les clients à visiter la page de votre restaurant. Formats acceptés : JPEG, PNG. Au moins 1920x1080 pixels, format 16:9.',
			confirm_delete:
				'Êtes-vous sûr de vouloir supprimer la photo de couverture de ce partenaire ?',
			add: 'Ajouter une photo de couverture',
		},
	},
	request: {
		fail: 'Erreur lors de la requête sur %{endPoint}',
	},
	breadcrumb: {
		partners: 'Partenaires',
		home: 'Accueil',
		info: 'Informations générales',
		eventWebhook: 'Webhooks',
	},
	variable: {
		title_modal: 'Attention',
		description_modal:
			'Des modifications de votre carte ont impacté un ou plusieurs de vos exports. Les exports concernés ont donc été modifiés en conséquence et automatiquement re-déployés.',
		got_it: 'OK',
		impacted_export: 'Export(s) concerné(s)',
		generate_new_export: 'Générer un nouvel export',
		multisession: {
			label:
				"Il semblerait qu'une session soit déjà ouverte sur ce service. Voulez-vous tout de même y accéder ? Cela fermera toute autre session active",
			button: 'Activer ma session',
		},
	},
	errorBoundary: {
		title: 'Une erreur est survenue',
		keepError:
			'Si le problème persiste merci de contacter notre support technique',
		reload: 'Recharger',
		errorCode: 'Code erreur',
		interjections: [
			'Par ma barbe !',
			'Diantre !',
			'Nom de Zeus Marty !',
			'Saperlipopette !',
			'Sacrebleu !',
			'Cornegidouille !',
		],
		configError:
			"Votre compte n'a pas été correctement configuré. Ne vous en faites pas, nous sommes sur le coup",
	},
	firstLogin: {
		fail:
			"Une erreur est survenue lors de l'initialisation du compte, veuillez contacter le support technique de L'Addition",
		init: 'Initialisation de votre compte',
		errors: {
			SET_INFO_FAIL:
				'Votre compte à bien été initialisé mais vos informations n\'ont pas pu être récupérées, pensez à les compléter dans la section "Informations".',
		},
	},
	404: {
		not_found: 'La page que vous recherchez n’existe pas',
		go_home: 'Revenir à l’Accueil',
	},
};
